/* footer */
footer {
    background-color: #333333;
    font-family: $light;
    font-size: 16px;
    color: #cbcbcb;
    a {
        color: #cbcbcb;
        &:focus {
            color: #cbcbcb;
        }
        &:hover {
            color: #87bc2c;
        }
    }
    ul {
        @include ul-default;
        li {
            margin: 15px 0;
            &.active a {
                color: #87bc2c;
            }
        }
    }
    .circle--list {
        li {
            &:after {
                top: 7px;
            }
        }
    }
    h4 {
        color: #ffffff;
        text-transform: uppercase;
        margin: 0 0 25px;
    }
    p {
        margin-bottom: 20px;
    }
    .up {
        border-bottom: 2px solid #5c5c5c;
        margin-bottom: 70px;
    }
    .container {
        display: flex;
        justify-content: space-between;
    }
    .footer__nav {
        display: flex;
        width: 100%;
        height: 95px;
        justify-content: space-between;
        align-items: center;
        li {
            a {
                color: #ffffff;
                text-transform: uppercase;
                &:focus {
                    color: #fff;
                }
                &:hover {
                    color: #87bc2c;
                }
            }
            &.active a {
                color: #87bc2c;
            }
        }
    }
    .footer__item {
        padding-right: 15px;
        &.f--cts {
            padding-right: 0;
            ul li {
                margin-bottom: 20px;
            }
        }
    }
    .down {
        background-color: #292929;
        font-size: 14px;
        color: #a1a1a1;
        margin-top: 50px;
        .container {
            height: 60px;
            align-items: center;
        }
        p {
            margin: 0;
        }
        a {
            color: #a1a1a1;
            &:hover {
                color: #a1a1a1;
            }
        }
        .artmedia {
            display: flex;
            align-items: center;
        }
    }
    @media screen and (max-width: 1440px) {
        .footer__nav {
            height: 80px;
            font-size: 14px;
        }
        .up {
            margin-bottom: 50px;
        }
        .down {
            margin-top: 40px;
        }
    }
    @media screen and (max-width: 1200px) {
        .footer__nav {
            height: 70px;
        }
        .up {
            margin-bottom: 30px;
        }
        h4 {
            margin-bottom: 20px;
        }
        .down {
            margin-top: 30px;
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        > .container {
            flex-wrap: wrap;
        }
        .footer__item {
            width: 50%;
            margin-bottom: 20px;
            &.f--cts {
                width: 100%;
                margin-bottom: 0;
            }
            ul {
                margin: 10px 0;
            }
        }
        h4 {
            margin-bottom: 15px;
        }
        .circle--list {
            li {
                &:after {
                    top: 6px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding-top: 30px;
        .footer__nav {
            display: none;
        }
        .up {
            border: none;
            margin: 0;
        }
        .down {
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 600px) {
        .down {
            text-align: center;
            .container {
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 15px;
            }
            .artmedia {
                margin-top: 5px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .footer__item {
            &.f--nav {
                display: none;
            }
        }
    }
}
/* footer */