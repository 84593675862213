/* page content */
.page {
    padding-top: 100px;
    padding-bottom: 50px;
	.news1, .news {
		.date {
			color: #87bc2c;
		}
	}
	.page_nav {
		.active {
			color: #87bc2c;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #87bc2c;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #87bc2c;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    h1 {
        font-size: 36px;
    }
    .h2 a {
        font-size: 16px;
        color: #87bc2c;
    }
    &.no-padding {
        overflow: hidden;
        padding-bottom: 0;
    }

    .small--photos {
        a {
            display: block;
            height: 180px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%!important;
                object-fit: cover;
                object-position: center top;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        h1 {
            font-size: 32px;
        }
    }
    @media screen and (max-width: 1200px) {
        h1 {
            font-size: 28px;
        }
        p iframe {
            max-width: 100%!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
        }
    }
	@media screen and (max-width: 1024px) {
        padding-top: 0;
        padding-bottom: 30px;
		#photos-list td img {
			width: 100%;
			height: auto;
        }
        h1 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 22px;
        }
        .small--photos {
            a {
                height: 140px;
            }
        }
    }
	@media screen and (max-width: 480px) {
		p img, p strong img, h2 img, h3 img, h4 img, h5 img, h6 img, p a img, strong img {
			max-width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
        }
        
        #page-text p a img {
            width: auto!important;
            max-width: 100%!important;
        }
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    font-size: 14px;
    padding: 35px 0px 45px;
	ul {
        @include ul-default;
        display: flex;
        flex-wrap: wrap;
		li {
			padding: 5px 0px;
			color: #87bc2c;
			@include inline-block;
			a {
                text-decoration: none;
                color: #666666;
                &:hover {
                    color: #87bc2c;
                }
			}
			&:after {
                content: '';
                width: 12px;
                height: 2px;
                background-color: #8c8c8c;
				margin: 8px 7px 0px 7px;
				color: #282727;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
    }
    @media screen and (max-width: 1440px) {
        font-size: 14px;
        padding: 25px 0 35px;
        ul li {
            &:after {
                margin-top: 8px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 10px 0 20px;
        line-height: 1;
        ul {
            li {
                &:after {
                    width: 8px;
                    margin: 6px 5px 0;
                }
            }
        }
    }
}
/* breadcrumbs */

/* page about */
.page {
    .about {
        &:after {
            display: none;
        }
        .container {
            border: none;
            padding-top: 0px;
        }
        .slider__list {
            height: 310px;
            width: 100%;
            position: relative;
            top: 0;
            margin: -220px 0 0 215px;
            border-radius: 0px 0px 0px 180px;
            padding-left: 335px;
            margin-bottom: 60px;
            z-index: 0;
            &:after {
                content: '';
                width: 100vw;
                background-color: #87bc2c;
                position: absolute;
                left: 100%;
                top: 0;
                bottom: 0;
            }
            .check--list {
                width: 100%;
                li {
                    margin: 45px 0;
                    &:before {
                        top: -3px;
                    }
                    br {
                        display: none;
                    }
                }
            }
        }
    }
    .about__block {
        align-items: flex-start;
        position: relative;
        .about__img {
            margin-top: -70px;
            margin-left: -120px;
            z-index: 1;
            &:before {
                display: none;
            }
        }
        &:before {
            content: '';
            position: absolute;
            left: -140px;
            top: -80px;
            width: 690px;
            height: 690px;
            border-radius: 100%;
            background-color: #fff;
            z-index: -1;
        }
    }
    h1 {
        position: relative;
        z-index: 1;
    }
    .about__down {
        padding: 0 45px;
    }
    @media screen and (max-width: 1440px) {
        .about .slider__list {
            height: 250px;
            padding-left: 240px;
            .check--list {
                li {
                    margin: 35px 0;
                }
            }
        }
        .about__block {
            &:before {
                width: 600px;
                height: 600px;
                left: -130px;
                top: -60px;
            }
            .about__img {
                width: 600px;
                height: 600px;
                padding: 60px;
                margin-top: -50px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .about {
            .slider__list {
                height: auto;
                padding: 15px 50px;
                margin: 25px 0 0 0;
                border-radius: 0 0 0 75px;
                &:after {
                    display: none;
                }
                .check--list {
                    li {
                        margin: 25px 0;
                    }
                }
            }
        }
        .about__block {
            align-items: center;
            &:before {
                display: none;
            }
            .about__img {
                width: 400px;
                height: 400px;
                padding: 0;
                margin: 15px 0;
            }
            .right {
                width: calc(100% - 400px);
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .about__block {
            .about__img {
                width: 300px;
                height: 300px;
            }
            .right {
                width: calc(100% - 300px);
            }
        }
        .about {
            .slider__list {
                padding: 25px 20px;
                border-radius: 0 0 0 50px;
                .check--list {
                    li {
                        margin: 10px 15px;
                    }
                }
            }
        }
        .about__down {
            padding: 0;
            h6 {
                margin: 30px 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .about {
            .slider__list {
                padding: 25px 15px;
                border-radius: 0 0 0 35px;
            }
        }
        .about__down {
            h6 {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .about__block {
            .right {
                width: 100%;
                margin-bottom: 15px;
            }
            .about__img {
                margin: 0 auto;
            }
        }
        .about {
            .slider__list {
                padding: 20px 20px;
                .check--list {
                    display: block;
                    li {
                        margin: 15px 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 420px) {
        .about__block {
            .about__img {
                width: 290px;
                height: 290px;
            }
        }
    }
}
.about__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 15px;
    border-top: 2px solid #f2f2f2;
    a {
        display: flex;
        width: 360px;
        height: 97px;
        background-color: #f2f2f2;
        align-items: center;
        justify-content: center;
        font-family: $boldS;
        font-size: 20px;
        color: #333333;
        border-radius: 0 35px 0 35px;
        margin: 0 80px;
        span {
            display: block;
            padding-right: 50px;
            position: relative;
            &:after {
                content: '';
                width: 19px;
                height: 13px;
                background: url(../img/nextG.png) no-repeat center center;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: 300ms;
            }
        }
        &:hover {
            background-color: #87bc2c;
            color: #fff;
            span {
                &:after {
                    background: url(../img/nextW.png) no-repeat center center;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 50px 15px;
    }
    @media screen and (max-width: 1024px) {
        padding: 30px 15px;
        a {
            width: 300px;
            height: 80px;
            font-size: 18px;
            margin: 0 15px;
            span {
                padding-right: 30px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        a {
            width: 250px;
            height: 70px;
            font-size: 16px;
            border-radius: 0 25px 0 25px;
            span {
                padding-right: 25px;
                &:after {
                    width: 16px;
                    height: 10px;
                    background-size: contain;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        padding: 20px 15px 0;
        a {
            height: 60px;
            margin: 10px auto;
        }
    }
}
/* page about */

/* page catalog */
.line--right {
    max-width: 1140px;
    border-right: 2px solid #f2f2f2;
    padding-bottom: 50px;
    margin: 0 auto;
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    @media screen and (max-width: 1200px) {
        border: none;
        .container {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 15px;
    }
}
.page {
    .cat__block {
        margin: 0 -15px;
    }
    @media screen and (max-width: 1024px) {
        .cat__block {
            margin: 0 -10px;
        }
    }
    @media screen and (max-width: 480px) {
        .cat__block {
            margin: 0;
        }
    }
}
.item--title {
    h1.h1 {
        font-size: 24px!important;
    }
    @media screen and (max-width: 1024px) {
        h1.h1 {
            font-size: 22px!important;
        }
    }
    @media screen and (max-width: 768px) {
        h1.h1 {
            font-size: 20px!important;
        }
    }
}
.cat__txt {
    border-top: 2px solid #f6f6f6;
    margin-bottom: -50px;
    position: relative;
    &:before {
        content: '';
        width: 50%;
        background-color: #f2f2f2;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
    .container {
        background-color: #fff;
        padding: 65px 95px;
        border-right: 2px solid #f6f6f6;
    }
    @media screen and (max-width: 1200px) {
        .container {
            padding: 50px  70px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: -30px;
        .container {
            padding: 30px  50px;
        }
    }
    @media screen and (max-width: 768px) {
        .container {
            padding: 20px 15px;
        }
    }
    @media screen and (max-width: 480px) {
        margin-top: 15px;
    }
}
.items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .items__col {
        display: flex;
        background-color: #e8e8e8;
        flex-direction: column;
        width: calc(25% - 30px);
        font-family: $boldS;
        color: #333333;
        margin: 0 15px 30px;
        border-radius: 0 35px 0 35px;
        overflow: hidden;
        .items__img {
            height: 215px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .items__title {
            display: flex;
            flex: 1 0 auto;
            align-items: center;
            justify-content: center;
            text-align: center;
            min-height: 90px;
            padding: 15px 10px;
        }
        &:hover {
            background-color: #87bc2c;
            color: #fff;
        }
    }
    @media screen and (min-width: 1440px) {
        .items__col {
            &:nth-last-of-type(-n+4) {
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -10px;
        .items__col {
            width: calc(25% - 20px);
            margin: 0 10px 20px;
            .items__img {
                height: 175px;
            }
            .items__title {
                min-height: 80px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .items__col {
            width: calc(50% - 20px);
            border-radius: 0 25px 0 25px;
            .items__img {
                height: 220px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        margin: 0;
        .items__col {
            width: 100%;
            margin: 0 0 20px;
            .items__title {
                min-height: 70px;
            }
        }
    }
    @media screen and (max-width: 340px) {
        .items__col .items__img {
            height: 200px;
        }
    }
}
.items__txt {
    background-color: #f2f2f2;
    padding: 70px 0 120px;
    margin: 0 0 -50px;
    @media screen and (max-width: 1440px) {
        padding: 50px 0 80px;
    }
    @media screen and (max-width: 1200px) {
        padding: 50px 0 50px;
    }
    @media screen and (max-width: 1024px) {
        padding: 30px 0;
        margin-bottom: -30px;
    }
    @media screen and (max-width: 768px) {
        padding: 25px 0;
        margin-top: 30px;
    }
}

.item {
    display: flex;
    justify-content: flex-end;
    min-height: 710px;
    position: relative;
    margin-bottom: -50px;
    .left {
        display: flex;
        background-color: #f2f2f2;
        flex-direction: column;
        width: calc(50vw - 80px);
        overflow: hidden;
        border-radius: 0px 105px 0px 0px;
        align-items: flex-end;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(50% + 80px);
        padding: 30px 30px 90px 0;
    }
    .right {
        width: 50%;
        padding-right: 70px;
    }
    ul {
        @include ul-default;
    }
    .item__list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -35px;
        li {
            width: 50%;
            padding-right: 15px;
            margin-bottom: 25px;
            img {
                display: block;
                height: 64px;
                max-width: 100%;
                object-fit: contain;
                object-position: center left;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 5px;
            }
            strong {
                font-family: $bold;
            }
        }
    }
    .txt {
        min-height: 520px;
        padding-bottom: 20px; 
    }
    .item__down {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        padding: 50px 20px 50px 0;
        margin-top: 20px;
        &:before {
            content: '';
            width: 60vw;
            height: 2px;
            background: #f2f2f2;
            position: absolute;
            top: 0;
            left: -80px;
        }
        p {
            margin-bottom: 5px;
        }
        .item__price {
            .price {
                font-family: $boldE;
                font-size: 24px;
                color: #333333;
            }
        }
    }
    .guarantee {
        width: 100%;
        padding-left: 70px;
        position: relative;
        margin-bottom: 20px;
        img {
            width: 45px;
            position: absolute;
            left: 0;
            top: 0;
        }
        h5 {
            text-transform: uppercase;
            margin: 0 0 5px;
        }
    }
    @media screen and (max-width: 1024px) {
        min-height: 0px;
        flex-wrap: wrap;
        padding: 0 15px;
        margin-bottom: -30px;
        .left {
            width: 100%;
            position: relative;
            right: 0;
            border-radius: 0 75px 0 0;
            padding-bottom: 20px;
        }
        .right {
            width: 100%;
            padding: 0;
            margin-top: 20px;
        }
        .txt {
            min-height: 0px;
            padding-bottom: 0;
        }
        .item__list {
            margin-right: 0;
        }
        .item__down {
            padding: 20px 0 40px;
            border-top: 2px solid #f2f2f2;
            &:before {
                display: none;
            }
        }
        .guarantee {
            padding-left: 50px;
            img {
                width: 40px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .left {
            padding: 20px 20px 20px 0;
            border-radius: 0 50px 0 0;
        }
    }
    @media screen and (max-width: 480px) {
        .left {
            border-radius: 0 35px 0 0;
        }
    }
}
.tg {
    font-weight: 900;
}
.item__for {
    width: 100%;
    .slick-slide {
        height: 490px;
        outline: none;
        padding: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center left;
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 350px;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 600px) {
        .slick-slide {
            height: 250px;
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 200px;
        }
    }
}
.item__nav {
    width: 460px;
    padding-right: 25px;
    margin-top: 20px;
    .slick-slide {
        height: 135px;
        margin: 0 7.5px;
        outline: none;
        position: relative;
        cursor: pointer;
        &:after {
            content: '';
            height: 6px;
            background: #87bc2c;
            position: absolute;
            left: 0;
            right: 100%;
            bottom: 0;
            transition: 300ms;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &.slick-current:after {
            right: 0;
        }
    }
    .slick-arrow {
        width: 18px;
        height: 16px;
        transition: 300ms;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            background: url(../img/arr.png) no-repeat center center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &.slick-prev {
            display: none!important;
        }
        &.slick-next {
            right: 0;
        }
        &:hover {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: 600px) {
        width: 375px;
        .slick-slide {
            height: 100px;
            &:after {
                height: 4px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        width: 100%;
        padding: 0 20px 0 10px;
    }
    @media screen and (max-width: 380px) {
        .slick-slide {
            height: 80px;
        }
    }
}

.pagination {
    margin: 30px 0 65px;
    ul {
        @include ul-default;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li {
            a {
                display: flex;
                height: 100%;
                width: 50px;
                justify-content: flex-end;
                align-items: flex-end;
                font-family: $boldE;
                font-size: 18px;
                color: #c1c1c1;
                line-height: 1;
                border-bottom: 2px solid #efefef;
                padding: 0 0 14px 0;
                &:hover {
                    color: #333;
                    border-bottom: 2px solid #87bc2c;
                }
            }
            &.active {
                a {
                    width: auto;
                    font-size: 30px;
                    color: #333333;
                    color: #333;
                    border-bottom: 2px solid #87bc2c;
                    padding: 0 0 11px 0;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        margin: 0px 0 50px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 10px;
        margin-bottom: 35px;
        ul {
            li {
                a {
                    width: 35px;
                    font-size: 16px;
                    padding-bottom: 10px;
                }
                &.active a {
                    font-size: 24px;
                    padding-bottom: 8px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
    }
}
/* page catalog */

/* page gallery */
.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .gallery__item {
        width: calc(33.33% - 30px);
        background-color: #f2f2f2;
        font-family: $boldS;
        font-size: 16px;
        text-align: center;
        color: #333333;
        overflow: hidden;
        margin: 0 15px 30px;
        border-radius: 0 40px 0 40px;
        .gallery__img {
            height: 250px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .gallery__title {
            display: flex;
            min-height: 70px;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }
        &:hover {
            background-color: #87bc2c;
            color: #fff;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -10px;
        .gallery__item {
            width: calc(33.33% - 20px);
            font-size: 14px;
            margin: 0 10px 20px;
            border-radius: 0 35px 0 35px;
            .gallery__img {
                height: 200px;
            }
            .gallery__title {
                min-height: 60px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .gallery__item {
            border-radius: 0 25px 0 25px;
            .gallery__img {
                height: 160px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .gallery__item {
            width: calc(50% - 20px);
        }
    }
    @media screen and (max-width: 420px) {
        margin: 0;
        .gallery__item {
            width: 100%;
            margin: 0 0 20px;
            .gallery__img {
                height: 220px;
            }
        }
    }
    @media screen and (max-width: 340px) {
        .gallery__item {
            .gallery__img {
                height: 200px;
            }
        }
    }
}
/* page gallery */

/* page news */
.news--item {
    display: flex;
    min-height: 215px;
    background-color: #f2f2f2;
    color: #464646;
    overflow: hidden;
    border-radius: 0px 35px 0 35px;
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 45px;
    }
    .news--item__img {
        width: 360px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .news--item__block {
        width: calc(100% - 360px);
        padding: 35px 30px 20px 30px;
        .date {
            display: block;
            color: #6b6b6b;
            margin-bottom: 26px;
            transition: 300ms;
        }
        h4 {
            font-family: $boldS;
            margin: 0 0 15px;
            transition: 300ms;
        }
        p {
            margin-bottom: 0;
        }
    }
    &:hover {
        background-color: #87bc2c;
        color: #f3f8ea;
        .news--item__block {
            .date {
                color: #f3f8ea;
            }
            h4 {
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 30px;
        }
        .news--item__img {
            width: 300px;
        }
        .news--item__block {
            width: calc(100% - 300px);
            padding: 30px 20px 20px 20px;
            .date {
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        border-radius: 0 25px 0 25px;
        .news--item__img {
            width: 250px;
        }
        .news--item__block {
            width: calc(100% - 250px);
        }
    }
    @media screen and (max-width: 480px) {
        flex-wrap: wrap;
        .news--item__img {
            width: 100%;
            height: 200px;
        }
        .news--item__block {
            width: 100%;
            padding: 20px;
            .date {
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .news--item__img {
            height: 175px;
        }
    }
}
/* page news */

/* page contacts */
.map {
    display: none;
    height: 400px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3); 
        pointer-events: none;
    }
    > ymaps, .ymaps-2-1-74-map {
        height: 100%!important;
    }
    @media screen and (max-width: 1440px) {
        height: 350px;
    }
    @media screen and (max-width: 1024px) {
        height: 300px;
    }
    @media screen and (max-width: 768px) {
        height: 250px;
    }
    @media screen and (max-width: 600px) {
        height: 220px;
    }
}
.cts--tab {
    display: flex;
    width: 1140px;
    justify-content: space-between;
    margin: 0 auto 75px;
    .cts--tab__item {
        width: calc(50% - 15px);
        background-color: #f2f2f2;
        padding: 30px 50px 10px 75px;
        border-radius: 0 0 0 35px;
        position: relative;
        cursor: pointer;
        transition: 300ms;
        .cts__title {
            display: flex;
            width: 210px;
            height: 62px;
            font-family: $bold;
            font-size: 20px;
            text-transform: uppercase;
            color: #333333;
            text-transform: uppercase;
            background-color: #f2f2f2;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 0 17px 0 0;
            padding: 10px 10px 0 10px;
            position: absolute;
            left: 0;
            top: -62px;
            transition: 300ms;
        }
        .col--location,
        .col--phone,
        .col--email {
            &:after {
                transition: 300ms;
            }
        } 
        &:hover, &.active {
            background-color: #87bc2c;
            color: #fff;
            a {
                color: #fff;
            }
            .cts__title {
                background-color: #87bc2c;
                color: #fff;
            }
            .col--location,
            .col--phone,
            .col--email {
                &:after {
                    color: #c3de96;
                }
            }
        }
    }
    ul {
        @include ul-default;
        li {
            margin: 0 0 27px;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 0 15px;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 50px;
        .cts--tab__item {
            padding: 20px 30px 20px 50px;
            .cts__title {
                height: 55px;
                width: 180px;
                font-size: 18px;
                top: -55px;
            }
        }
        ul li {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
        .cts--tab__item {
            width: calc(50% - 10px);
            padding: 20px 20px 20px 30px;
            .cts__title {
                width: 150px;
                height: 50px;
                font-size: 16px;
                top: -50px;
            }
        }
        ul li {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 600px) {
        .cts--tab__item {
            padding: 15px 15px 10px 15px;
            border-radius: 0 0 0 25px;
            .cts__title {
                height: 45px;
                width: 120px;
                font-size: 14px;
                top: -45px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        flex-wrap: wrap;
        width: auto;
        margin: 0;
        .cts--tab__item {
            width: calc(100% + 30px);
            background-color: #fff;
            border-radius: 0;
            margin: 0 -15px;
            .cts__title {
                width: auto;
                height: auto;
                background-color: transparent;
                justify-content: flex-start;
                position: relative;
                text-align: left;
                padding: 0;
                top: 0;
                margin-bottom: 10px;
                border-radius: 0;
            }
            &:hover {
                .cts__title {
                    background-color: transparent;
                }
            }
        }
    }
}
.cts--form {
    background-color: #f2f2f2;
    padding: 65px 0 120px;
    margin-bottom: -50px;
    h2 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 25px;
    }
    .cts--form__inp {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7.5px;
        input {
            width: calc(33.33% - 15px);
            margin: 0 7.5px 15px;
        }
        &.down {
            justify-content: space-between;
            align-items: flex-start;
            textarea {
                height: 77px;
                width: calc(100% - 335px);
                margin: 0 7.5px 15px;
            }
            .g-recaptcha {
                margin: 0 7.5px 15px;
            }
        }
    }
    .btn {
        margin: 0 auto;
    }
    @media screen and (max-width: 1440px) {
        padding: 50px 0 80px;
        h2 {
            font-size: 26px;
        }
    }
    @media screen and (max-width: 1200px) {
        h2 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 35px 0 50px;
        margin-bottom: -30px;
        h2 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 30px 0 30px;
        .cts--form__inp {
            input {
                margin-bottom: 10px;
            }
            &.down {
                textarea {
                    margin-bottom: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cts--form__inp {
            &.down {
                textarea {
                    width: 100%;
                }
            }
        }
        .btn {
            float: right;
            margin-top: -92px;
        }
    }
    @media screen and (max-width: 460px) {
        padding-bottom: 160px;
        .cts--form__inp {
            input {
                width: calc(50% - 15px);
                &[type="email"] {
                    width: calc(100% - 15px);
                }
            }
            &.down {
                display: block;
                margin: 0;
                textarea {
                    margin: 0 0 10px;
                }
                .g-recaptcha {
                    float: right;
                    margin: 0 0 15px 0;
                }
            }
        }
        .btn {
            margin: 0;
        }
    }
    @media screen and (max-width: 340px) {
        .cts--form__inp {
            display: block;
            margin: 0;
            input {
                width: 100%;
                margin: 0 0 10px;
                &[type="email"] {
                    width: 100%;
                    margin: 0 0 10px;
                }
            }
            &.down {
                .g-recaptcha {
                    transform: scale(0.9);
                    transform-origin: 100% 0;
                    margin-bottom: 5px;
                }
            }
        }
    }
}
/* page contacts */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    padding: 50px 20px;
    overflow: auto;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 480px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 25px auto;
        transform: translateY(-50%);
        h4 {
            font-family: $boldS;
            font-size: 20px;
            font-weight: normal;
            text-align: center;
            padding: 0 20px;
            margin: 0;
        }
        h5 {
            font-family: $boldS;
            text-align: center;
            margin: 15px 0;
        }
        h2 {
            font-family: $boldE;
            font-size: 30px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 50px;
            width: 100%;
            background-color: rgba(255,255,255,0.9);
            font-family: $regular;
            font-size: 16px;
            padding: 15px 30px;
            margin-bottom: 15px;
            box-shadow: none;
            border: 1px solid #d2d1d2;
            &::placeholder {
                color: #7c7b7b;
            }
        }
        textarea {
            height: 130px;
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    .modal__choice {
        display: flex;
        justify-content: space-between;
        ul {
            @include ul-default;
            li {
                a {
                    display: table;
                    font-size: 16px;
                    color: #333333;
                    padding: 8px 20px;
                    &:hover {
                        background-color: #87bc2c;
                        color: #fff;
                    }
                }
                &.active a {
                    background-color: #87bc2c;
                    color: #fff;
                    font-family: $boldS;
                }
            }
        }
    }
    &#modal--choice {
        .modal__block {
            width: 755px;
            padding: 50px;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
            h2 {
                font-size: 26px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 50px;
                font-size: 16px;
                padding: 14px 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 15px;
            }
            .btn {
                width: 150px!important;
            }
        }
        .modal__block {
            h2 {
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
        .modal__choice ul li a {
            font-size: 14px;
            padding: 5px 15px;
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
            h2 {
                font-size: 20px;
                margin-bottom: 15px;
            }
        }
        &#modal--choice .modal__block {
            width: auto;
            left: 15px;
            right: 15px;
            padding: 30px;
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                margin-bottom: 10px;
            }
            textarea {
                height: 100px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .modal__choice {
            flex-wrap: wrap;
            ul {
                width: 50%;
                margin-bottom: 15px;
                &:nth-last-of-type(-n+2) {
                    margin-bottom: 0;
                }
            }
        }
        .modal__block {
            h2 {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
        .modal__choice ul li a {
            font-size: 13px;
            padding: 4px 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            padding-bottom: 120px;
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 0%;
            }
            .btn {
                margin-top: 0;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 150px;
    right: 40px;
    display: table;
    background-color: #87bc2c;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 23px 5px 5px 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #87bc2c;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
        border: 1px solid #87bc2c;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        bottom: 50px;
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */