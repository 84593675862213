/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'proxima_novablack';
    src: url('../fonts/proximanova-black-webfont.eot');
    src: url('../fonts/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova-black-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-black-webfont.woff') format('woff'),
         url('../fonts/proximanova-black-webfont.ttf') format('truetype'),
         url('../fonts/proximanova-black-webfont.svg#proxima_novablack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novabold';
    src: url('../fonts/proximanova-bold-webfont.eot');
    src: url('../fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova-bold-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-bold-webfont.woff') format('woff'),
         url('../fonts/proximanova-bold-webfont.ttf') format('truetype'),
         url('../fonts/proximanova-bold-webfont.svg#proxima_novabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novalight';
    src: url('../fonts/proximanova-light-webfont.eot');
    src: url('../fonts/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova-light-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-light-webfont.woff') format('woff'),
         url('../fonts/proximanova-light-webfont.ttf') format('truetype'),
         url('../fonts/proximanova-light-webfont.svg#proxima_novalight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaregular';
    src: url('../fonts/proximanova-regular-webfont.eot');
    src: url('../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova-regular-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-regular-webfont.woff') format('woff'),
         url('../fonts/proximanova-regular-webfont.ttf') format('truetype'),
         url('../fonts/proximanova-regular-webfont.svg#proxima_novaregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novasemibold';
    src: url('../fonts/proximanova-semibold-webfont.eot');
    src: url('../fonts/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova-semibold-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-semibold-webfont.woff') format('woff'),
         url('../fonts/proximanova-semibold-webfont.ttf') format('truetype'),
         url('../fonts/proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaextrabold';
    src: url('../fonts/proximanova-extrabld-webfont.eot');
    src: url('../fonts/proximanova-extrabld-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova-extrabld-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-extrabld-webfont.woff') format('woff'),
         url('../fonts/proximanova-extrabld-webfont.ttf') format('truetype'),
         url('../fonts/proximanova-extrabld-webfont.svg#proxima_novaextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'proxima_novaregular';
$bold: 'proxima_novabold';
$boldE: 'proxima_novaextrabold';
$boldS: 'proxima_novasemibold';
$light: 'proxima_novalight';
$black: 'proxima_novablack';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

[data-src] {
	opacity: 0;
	&[src] {
		opacity: 1;
		transition-duration: 400ms;
		transition-delay: 0.2s;
	}
}

.preload {
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	pointer-events: none;
	&.load {
		opacity: 0;
		transition: 500ms;
	}
	&.loadR {
		opacity: 1;
		transition: 500ms;
	}
	// .sk-double-bounce {
	// 	width: 80px;
	// 	height: 80px;
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 0;
	// 	right: 0;
	// 	transform: translateY(-50%);
	// 	margin: auto;
	// 	.sk-child {
	// 	    width: 100%;
	// 	    height: 100%;
	// 	    border-radius: 50%;
	// 	    background-color: #099;
	// 	    opacity: 0.6;
	// 	    position: absolute;
	// 	    top: 0;
	// 	    left: 0;
	// 	    animation: sk-double-bounce 2.0s infinite ease-in-out;
	// 	}
	//   	.sk-double-bounce-2 {
	//     	animation-delay: -1.0s;
	//   	}
	// }
}
@keyframes sk-double-bounce {
    0%, 100% {
    	transform: scale(0);
  	}
  	50% {
    	transform: scale(1.0);
  	}
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
	color: #535353;
	margin: 0;
	@media screen and (max-width: 1024px) {
		padding-top: 80px;
	}
    @media screen and (max-width: 768px) {
        display: block;
    }
}

h1 {
	font-family: $boldE;
	font-weight: normal;
	font-size: 42px;
	text-transform: uppercase;
	color: #333333;
	line-height: 1;
	margin: 0 0 40px;
	&.h1 {
		font-family: $regular;
		font-size: 30px!important;
	}
	@media screen and (max-width: 1440px) {
		&.h1 {
			font-size: 26px!important;
		}
	}
	@media screen and (max-width: 1200px) {
		font-size: 32px;
		margin-bottom: 30px;
		&.h1 {
			font-size: 24px!important;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 25px;
		&.h1 {
			font-size: 22px!important;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
		margin-bottom: 15px;
		&.h1 {
			font-size: 20px!important;
		}
	}
	@media screen and (max-width: 600px) {
		font-size: 22px;
	}
}
h2  {
	font-family: $boldE;
	font-weight: normal;
	font-size: 36px;
	color: #333333;
	line-height: 1;
	margin: 0 0 40px;
	a {
		color: #333333;
		&:hover {
			color: #87bc2c;
		}
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 25px;
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 15px;
	}
	@media screen and (max-width: 600px) {
		font-size: 20px;
	}
}
h3 {
	font-family: $bold;
	font-weight: normal;
	font-size: 24px;
	color: #333333;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-weight: normal;
	font-size: 20px;
	color: #333333;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
h5 {
	font-family: $bold;
	font-weight: normal;
	font-size: 18px;
	color: #333333;
	@media screen and (max-width: 1024px) {
		font-size: 16px;
	}
}
h6 {
	font-family: $bold;
	font-weight: normal;
	font-size: 16px;
	color: #333333;
}
p {
    margin: 0 0 15px;
}
a {
    color: #535353;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #535353;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #87bc2c;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.wrapper, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 16px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        min-height: 50px;
        width: 100%;
		background-color: rgba(255,255,255,0.9);
		font-family: $regular;
		font-size: 16px;
		padding: 15px 30px;
        margin-bottom: 15px;
		box-shadow: none;
		border: 1px solid #d2d1d2;
		&::placeholder {
			color: #7c7b7b;
		}
	}
	.container {
		width: 1140px;
	}
	h2 {
		text-transform: uppercase;
	}
	@media screen and (max-width: 1200px) {
		.container {
			width: 100%;
		}
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"] {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 14px;
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"] {
			min-height: 45px;
			font-size: 14px;
			padding: 11px 20px;
		}
	}
	@media screen and (max-width: 768px) {
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"] {
			min-height: 40px;
			font-size: 14px;
			padding: 10px 15px;
			margin-bottom: 10px;
		}
	}
}
.wrapper {
	.container {
		padding-top: 120px;
		padding-bottom: 120px;
		@media screen and (max-width: 1440px) {
			padding-top: 100px;
			padding-bottom: 100px;
		}
		@media screen and (max-width: 1200px) {
			padding-top: 75px;
			padding-bottom: 75px;
		}
		@media screen and (max-width: 1024px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
	margin: 0 auto;
	@media screen and (max-width: 1200px) {
		width: 100%;
	}
}

.close {
    width: 17px;
    height: 17px;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #dddddd;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
	}
	@media screen and (max-width: 600px) {
		top: 10px;
		right: 10px;
	}
}
.btn {
	display: table;
	width: auto!important;
	height: 50px;
	background-color: #87bc2c!important;
	font-family: $boldS!important;
	font-size: 16px;
	color: #fff!important;
	text-transform: uppercase;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: none;
	padding: 17px 40px 15px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #87bc2c!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #333333!important;
		color: #fff!important;
	}	
	@media screen and (max-width: 1440px) {
		height: 45px;
		font-size: 14px;
		padding: 16px 35px 15px!important;
	}
	@media screen and (max-width: 1024px) {
		height: 40px;
		padding: 14px 25px 13px!important;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 20px;
		padding-left: 45px;
		margin: 25px 0;
		&:before {
			content: '';
			width: 30px;
			height: 30px;
			background: url(../img/check.png) no-repeat center center;
			position: absolute;
			left: 0;
			top: 0px;
		}
	}
	@media screen and (max-width: 1440px) {
		li {
			padding-left: 35px;
			font-size: 16px;
			margin: 20px 0;
			&:before {
				width: 25px;
				height: 25px;
				background-size: contain;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		li {
			font-size: 14px;
			padding-left: 25px;
			margin: 15px 0;
			&:before {
				width: 20px;
				height: 20px;
			}
		}
	}
}

.circle--list {
	@include ul-default;
	margin: 20px 0;
	li {
		position: relative;
		padding-left: 16px;
		margin: 10px 0;
		&:after {
			content: '';
			width: 5px;
			height: 5px;
			background-color: #87bc2c;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 7px;
		}
	}
	@media screen and (max-width: 1024px) {
		li {
			&:after {
				top: 6px;
			}
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 22px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #87bc2c;
		position: absolute;
		top: 0px;
		left: 0;
	}
	@media screen and (max-width: 1024px) {
		padding-left: 20px;
		&:after {
			font-size: 14px;
		}
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone {
	pointer-events: none;
	&:after {
		content: '\f095';
		top: 2px;
	}
	a {
		display: table;
	}
	@media screen and (max-width: 1024px) {
		a {
			pointer-events: auto;
		}
	}
}
.col--email {
	&:after {
		content: '\f0e0';
		font-size: 12px;
		top: 4px;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	@media screen and (max-width: 1024px) {
		&:after {
			font-size: 11px;
			top: 3px;
		}
	}
}

.green {
	color: #87bc2c;
}

.title--txt {
	display: block;
	font-size: 16px;
	text-transform: uppercase;
	color: #87bc2c;
	margin-bottom: 10px;
	@media screen and (max-width: 1024px) {
		margin-bottom: 5px;
	}
}

/* header */
header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	color: #fff;
	z-index: 9;
	.container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	> .container {
		height: 56px;
		align-items: center;
	}
	.up {
		background-color: rgba(255,255,255,0.3);
		font-size: 16px;
		.container {
			height: 43px;
		}
		.close {
			display: none;
		}
	}
	ul {
		@include ul-default;
	}

	a {
		&:hover, &:focus {
			color: #fff;
		}
	}

	/* header logo */
	.logo {
		position: absolute;
		left: 90px;
		top: 11px;
	}
	/* header logo */

	/* header up */
	.choice--city {
		background: url(../img/arr-gr.svg) no-repeat center left;
		background-size: 18px;
		color: #fff;
		padding-left: 30px;
		margin-right: 90px;
		&:hover {
			color: #87bc2c;
		}
		a {
			color: #87bc2c;
			border-bottom: 1px dashed #87bc2c;
			position: relative;
			&:after {
				content: '\f107';
				font-family: 'fontAwesome';
				font-size: 16px;
				color: #87bc2c;
				position: absolute;
				top: 50%;
				right: -14px;
				transform: translateY(-50%);
			}
		}
	}
	.phones {
		position: relative;
		padding-left: 22px;
		padding-right: 16px;
		&:after {
			content: '\f095';
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #87bc2c;
			position: absolute;
			left: 0;
			top: 2px;
		}
		a {
			pointer-events: none;
			color: #fff;
		}
		ul {
			display: none;
			background-color: #87bc2c;
			padding: 10px 15px;
			position: absolute;
			top: calc(100% + 11px);
			left: 50%;
			margin-left: -80px;
			z-index: 1;
			li {
				margin: 2px 0;
				white-space: nowrap;
			}
		}
	}
	.h--arr {
		pointer-events: auto!important;
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #87bc2c;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-55%);
		&:after {
			content: '\f107';
		}
		&.open {
			transform: rotate(-180deg) translateY(50%);
		}
	}
	/* header up */

	/* header nav */
	.nav {
		> ul {
			display: flex;
			> li {
				position: relative;
				margin: 0 0 0 15px;
				> a {
					display: flex;
					align-items: center;
					height: 56px;
					font-size: 16px;
					text-transform: uppercase;
					color: #fff;
					padding: 10px 0;
					&:hover {
						color: #87bc2c;
					}
				}
				&.active > a {
					color: #87bc2c;
				}
				ul {
					display: none;
					background-color: #87bc2c;
					position: absolute;
					top: 100%;
					left: -28px;
					padding: 20px 30px 20px 10px;
					border-radius: 0px 0px 35px 0;
					li {
						display: block;
						white-space: nowrap;
						position: relative;
						margin: 0 18px;
						// &:after {
						// 	content: '';
						// 	width: 1px;
						// 	height: 16px;
						// 	background-color: #fff;
						// 	position: absolute;
						// 	top: 50%;
						// 	transform: translateY(-50%);
						// 	right: -20px;
						// }
						&:last-of-type:after {
							display: none;
						}
						a {
							color: #ffffff;
							&:hover {
								text-decoration: underline;
							}
						}
						&.active a {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	/* header nav */
	@media screen and (max-width: 1440px) {
		.logo {
			left: 50px;
		}
	}
	@media screen and (max-width: 1366px) {
		.logo {
			left: 25px;
		}
		.nav > ul > li {
			ul {
				padding: 15px 25px 15px 15px;
				border-radius: 0 0 25px 0;
				li {
					margin: 0 15px;
				}
			}
		}
	}
	@media screen and (min-width: 1025px) {
		&.header {
			background-color: #ffffff;
			position: fixed;
			box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
			.up {
				color: #333333;
				position: relative;
				&:before {
					content: '';
					background-color: #f2f2f2;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 50%;
					margin-left: -305px;
					border-radius: 0 0 0 45px;
					z-index: -1;
				}
			}
			.nav > ul > li {
				> a {
					color: #333333;
					&:hover {
						color: #87bc2c;
					}
				}
				&.active > a {
					color: #87bc2c;
				}
			}
			.phones {
				a {
					color: #333333;
				}
				ul li a {
					color: #fff;
				}
			}
		}
	}
	
	@media screen and (max-width: 1024px) {
		position: fixed;
		background-color: #87bc2c;
		box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
		.up {
			background-color: transparent;
			.container {
				height: 40px;
				justify-content: space-between;
			}
		}
		> .container {
			height: 40px;
		}
		.choice--city {
			a {
				color: #fff;
				border-bottom: 1px solid #fff;
				&:after {
					color: #fff;
				}
			}
		}
		.logo {
			width: 140px;
			top: 7px;
			left: 0;
			right: 0;
			margin: 0 auto;
			img {
				width: 100%;
			}
		}
		.choice--city {
			margin: 0;
		}
		.phones {
			&:after {
				color: #fff;
			}
			ul {
				z-index: 2;
				top: calc(100% + 5px);
				padding: 5px 10px;
				margin-left: -70px;
			    box-shadow: 0px 0px 7px rgba(0,0,0,0.3);
			}
		}
		.nav {
			display: none;
			max-height: calc(100% - 80px);
			background-color: #fff;
			position: fixed;
			top: 80px;
			left: 0;
			right: 0;
			padding: 5px 15px;
			overflow: auto;
			border-top: 1px solid #87bc2c;
			border-bottom: 1px solid #87bc2c;
			z-index: 1;
			> ul {
				display: block;
				> li {
					margin: 15px 0;
					> a {
						height: auto;
						font-size: 14px;
						color: #535353;
						padding: 0;
					}
					ul {
						position: relative;
						top: 0;
						left: 0;
						padding: 5px 10px;
						border-radius: 0px;
						margin-top: 10px;
						li {
							display: table;
							white-space: inherit;
							margin: 5px 0;
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 35px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		height: 80px;
		.up {
			display: none;
			background-color: rgba(135, 188, 44, 0.95);
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 3;
			padding: 20px;
			.container {
				display: block;
				height: auto;
				padding: 0;
			}
			.choice--city {
				display: table;
				padding: 0;
				background: none;
				border-bottom: 1px dashed #fff;
				margin: 0 auto 10px;
			}
			.phones {
				display: table;
				margin: 0 auto;
				a {
					pointer-events: auto;
				}
				.h--arr {
					display: none;
				}
				ul {
					display: block;
					background-color: transparent;
					position: relative;
					top: 0;
					left: 0;
					padding: 0;
					margin: 0;
					box-shadow: none;
					li {
						margin: 2px 0 0;
					}
				}
			}
			.close {
				display: block;
				top: 10px;
				right: 10px;
			}
		}
		> .container {
			height: auto;
		}
		.nav--btn {
			top: 25px;
		}
		.phones--btn {
			font-family: 'fontAwesome';
			font-size: 20px;
			position: fixed;
			top: 28px;
			right: 15px;
			color: #fff;
			&:after {
				content: '\f095';
			}
			&:hover, &:focus {
				color: #fff;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.choice--city {
			a {
				display: table;
				margin: 0 auto;
			}
		}
	}
}
/* header */