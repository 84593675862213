/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-list {
		position: relative;
		&:before {
			content: '';
			width: 340px;
			background-color: #fff;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 1;
		}
		&:after {
			content: '';
			width: 150px;
			height: 150px;
			background: url(../img/bg.png) no-repeat center center;
			position: absolute;
			top: 0;
			left: 340px;
		}
	}
	.slick-slide {
		height: 100vh;
		position: relative;
		outline: none;
		&::before {
			content: '';
			background-color: rgba(0,0,0,0.4);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&:after {
			content: '';
			height: 220px;
			background: linear-gradient(to top, rgba(135, 188, 44, 0.3), rgba(135, 188, 44, 0));
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		.slider__block {
			width: 800px;
			position: absolute;
			left: 50%;
			top: 55%;
			font-size: 18px;
			color: #fff;
            margin-left: -225px;
			transform: translateY(-50%);
			z-index: 1;
			.slider__title {
				display: block;
				font-family: $boldE;
				font-size: 54px;
				text-transform: uppercase;
				margin-bottom: 25px;
				.light {
					display: block;
					font-family: $light;
				}
			}
			.btn {
				margin-top: 25px;
			}
		}
	}
	.slick-dots {
		display: flex;
		width: 340px;
		right: auto;
		justify-content: center;
		align-items: flex-end;
		top: 50%;
		bottom: auto;
		margin-top: -50px;
		line-height: 1;
		li {
			display: flex;
			width: auto;
			height: 58px;
			align-items: flex-end;
			margin: 0px;
			border-bottom: 2px solid #efefef;
			position: relative;
			&:after {
				content: '';
				height: 2px;
				background-color: #87bc2c;
				position: absolute;
				left: 0;
				bottom: -2px;
				transition: 300ms;
			}
			span {
				display: block;
				font-family: $boldE;
				font-size: 24px;
				color: #c1c1c1;
				transition: 300ms;
				padding: 10px 0px 15px 15px;
			}
			&.slick-active {
				span {
					font-size: 50px;
					color: #333333;
					padding: 0 7px 8px 7px;
				}
				&:after {
					width: 100%;
				}
			}
		}
	}
	.slick-arrow {
		width: 60px;
		height: 60px;
		background-color: #fff;
		border: 4px solid #f3f8e9;
		border-radius: 100%;
		z-index: 1;
		top: 50%;
		left: 140px;
		right: auto;
		transform: translate(0,0);
		margin-top: -250px;
		&:before {
			display: none;
		}
		&:after {
			content: '';;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: url(../img/arr.png) no-repeat center center;
		}
		&.slick-prev {
			margin-top: -175px;
			&:after {
				transform: rotate(-180deg);
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-list {
			&:after {
				width: 125px;
				height: 125px;
				left: 275px;
				background-size: contain;
				background-position: top left;
			}
			&:before {
				width: 275px;
			}
		}
		.slick-slide {
			&:after {
				height: 175px;
			}
			.slider__block {
				font-size: 16px;
				.slider__title {
					font-size: 42px;
				}
			}
		}
		.slick-arrow {
			width: 50px;
			height: 50px;
			left: 110px;
			&.slick-prev {
				margin-top: -135px;
			}
			&.slick-next {
				margin-top: -200px;
			}
		}
		.slick-dots {
			width: 275px;
			li {
				span {
					font-size: 20px;
				}
				&.slick-active {
					span {
						font-size: 40px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1366px) {
		.slick-list {
			&:after {
				width: 90px;
				height: 90px;
				left: 200px;
			}
			&:before {
				width: 200px;
			}
		}
		.slick-arrow {
			width: 45px;
			height: 45px;
			left: 75px;
			&:after {
				background-size: 15px;
			}
			&.slick-next {
				margin-top: -170px;
			}
			&.slick-prev {
				margin-top: -115px;
			}
		}
		.slick-slide {
			.slider__block {
				width: 600px;
				.slider__title {
					font-size: 38px;
					margin-bottom: 15px;
				}
			}
		}
		.slick-dots {
			width: 200px;
			margin-top: -40px;
			li {
				span {
					font-size: 18px;
					padding-bottom: 10px;
				}
				&.slick-active {
					span {
						font-size: 34px;
						padding-left: 5px;
						padding-right: 5px;
						padding-bottom: 5px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-list {
			&:before, &:after {
				display: none;
			}
		}
		.slick-slide {
			height: auto;
			&:after {
				height: 130px;
			}
			img {
				height: auto;
			}
			.slider__block {
				width: auto;
				left: 60px;
				right: 60px;
				top: 50%;
				margin: 0;
				.slider__title {
					font-size: 30px;
				}
			}
		}
		.slick-dots {
			width: 100%;
			left: 0;
			right: 0;
			top: auto;
			margin: 0;
			bottom: 25px;
			li {
				height: 45px;
				span {
					padding: 0 10px 10px;
				}
				&.slick-active {
					span {
						font-size: 28px;
						color: #87bc2c;
						padding-bottom: 8px;
					}
				}
			}
		}
		.slick-arrow {
			width: 35px;
			height: 35px;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
			&.slick-prev {
				left: 10px;
				margin: 0;
			}
			&.slick-next {
				left: auto;
				right: 10px;
				margin: 0;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			&:after {
				height: 100px;
			}
			.slider__block {
				font-size: 14px;
				.slider__title {
					font-size: 24px;
				}
				.btn {
					margin-top: 20px;
				}
			}
		}
		.slick-arrow {
			width: 30px;
			height: 30px;
		}
	}
	@media screen and (max-width: 600px) {
		.slick-slide {
			.slider__block {
				left: 30px;
				right: 30px;
			}
		}
		.slick-arrow {
			display: none!important;
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			height: 300px;
			img {
				height: 100%;
			}
			.slider__block {
				top: 40%;
				left: 20px;
				right: 20px;
				.slider__title {
					font-size: 20px;
					margin-bottom: 10px;
				}
			}
		}
	}
}
.slider__list {
	display: flex;
	width: 520px;
	height: 475px;
	align-items: center;
	justify-content: center;
	background-color: #87bc2c;
	color: #fff;
	position: absolute;
	left: 0;
	top: 100vh;
	padding: 20px;
	margin-top: -385px;
	border-radius: 0px 0px 140px 0;
	z-index: 1;
	ul li {
		margin: 35px 0;
	}
	@media screen and (max-width: 1440px) {
		width: 360px;
		height: 320px;
		margin-top: -315px;
		border-radius: 0px 0px 120px 0;
		ul li {
			margin: 25px 0;
		}
	}
	@media screen and (max-width: 1366px) {
		width: 300px;
		height: 275px;
		border-radius: 0px 0px 100px 0;
		margin-top: -275px;
		ul li {
			margin: 20px 0;
		}
	}
	@media screen and (max-width: 1024px) {
		height: auto;
		width: 100%;
		position: relative;
		top: 0;
		margin: 0;
		border-radius: 0px 0px 50px 0;
		.check--list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			li {
				margin: 15px 15px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		border-radius: 0px 0px 35px 0;
	}
	@media screen and (max-width: 480px) {
		.check--list {
			width: 100%;
			flex-direction: column;
			li {
				margin: 10px 0;
			}
		}
	}
}
.line1 {
	position: absolute;
	height: 100vh;
	width: 2px;
	background-color: #3c3d39;
	position: absolute;
	left: 50%;
	top: 0;
	margin-left: -310px;
	z-index: 1;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}
.line2 {
	width: calc(100% - 340px);
	height: 2px;
	background-color: #3c3d39;
	position: absolute;
	right: 0;
	top: calc(100vh - 158px);
	z-index: 1;
	@media screen and (max-width: 1440px) {
		width: calc(100% - 275px);
		top: calc(100vh - 120px);

	}
	@media screen and (max-width: 1366px) {
		width: calc(100% - 200px);
		top: calc(100vh - 100px);
	}
	@media screen and (max-width: 1024px) {
		display: none;
	}
}
.slider--plus {
	position: absolute;
	width: 24px;
	height: 24px;
	top: calc(100vh - 169px);
	z-index: 1;
	left: 50%;
	margin-left: -321px;
	&:before, &:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #80a63f;
		left: 0;
		right: 0;
		top: 50%;
		margin-top: -1px;
	}
	&:after {
		transform: rotate(-90deg);
	}
	@media screen and (max-width: 1440px) {
		top: calc(100vh - 131px);
	}
	@media screen and (max-width: 1366px) {
		top: calc(100vh - 111 px);
	}
	@media screen and (max-width: 1024px) {
		display: none;
	}
}
/* main slider */

/* main about */
.about {
	position: relative;
	overflow: hidden;
	&:before {
		content: '';
		width: 50%;
		background-color: #f2f2f2;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: -1;
	}
	&:after {
		content: '';
		width: 560px;
		height: 560px;
		background-color: #f2f2f2; 
		border-radius: 100%;
		position: absolute;
		bottom: calc(100% - 155px);
		right: 50%;
		margin-right: -845px;
	}
	.container {
		background-color: #fff;
		border: 2px solid #f2f2f2;
		padding-bottom: 50px;
		padding-top: 175px;
	}
	h1 {
		font-size: 36px;
	}
	@media screen and (max-width: 1440px) {
		h1 {
			font-size: 32px;
		}
	}
	@media screen and (max-width: 1200px) {
		h1 {
			font-size: 28px;
		}
		.container {
			padding-top: 125px;
		}
	}
	@media screen and (max-width: 1024px) {
		&:after {
			display: none;
		}
		h1 {
			font-size: 24px;
		}
		.container {
			border: none;
			padding-top: 75px;
			padding-bottom: 25px;
		}
	}
	@media screen and (max-width: 768px) {
		.container {
			padding-top: 50px;
			padding-bottom: 25px;
		}
	}
	@media screen and (max-width: 600px) {
		.container {
			padding-top: 30px;
		}
	}
}
.about__block {
	display: flex;
	align-items: flex-start;
	.about__img {
		width: 690px;
		height: 690px;
		border-radius: 100%;
		padding: 80px;
		margin-left: -95px;
		margin-top: -75px;
		position: relative;
		z-index: 1;
		&:before {
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			background-color: #fff;
			z-index: -1;
			border-radius: 100%;
		}
		.img {
			width: 100%;
			height: 100%;
			overflow: hidden;
			border-radius: 100%;
			box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	.right {
		width: calc(100% - 550px);
		margin-left: -20px;
		position: relative;
		z-index: 1;
	}
	.btn {
		margin-top: 35px;
	}
	@media screen and (max-width: 1200px) {
		.about__img {
			width: 450px;
			height: 450px;
			padding: 0;
			margin: 0;
		}
		.right {
			width: calc(100% - 450px);
			padding-left: 30px;
			margin: 0;
		}
	}
	@media screen and (max-width: 1024px) {
		.btn {
			margin-top: 25px;
		}
	}
	@media screen and (max-width: 768px) {
		.about__img {
			width: 350px;
			height: 350px;
		}
		.right {
			width: calc(100% - 350px);
		}
	}
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
		.about__img {
			margin: 0 auto;
			order: 2;
			margin-top: 25px;
		}
		.right {
			width: 100%;
			padding: 0;
			order: 1;
		}
	}
	@media screen and (max-width: 420px) {
		.about__img {
			width: 300px;
			height: 300px;
		}
	}
	@media screen and (max-width: 340px) {
		.about__img {
			width: 280px;
			height: 280px;
		}
	}
}
/* main about */

/* main catalog */
.cat {
	.container {
		border: 2px solid #f2f2f2;
		border-top: none;
		border-bottom: none;
		padding-bottom: 100px;
	}
	@media screen and (max-width: 1440px) {
		.container {
			padding-bottom: 75px;
		}
	}
	@media screen and (max-width: 1366px) {
		.container {
			padding-bottom: 50px;
		}
	}
	@media screen and (max-width: 1024px) {
		.container {
			border: none;
			padding-bottom: 30px;
		}
	}
}
.cat__up {
	padding: 0px 100px 10px 100px;
	.more {
		float: right;
		margin-top: -69px;
	}
	@media screen and (max-width: 1200px) {
		.more {
			margin-top: -54px;
		}
	}
	@media screen and (max-width: 1024px) {
		padding: 0px 0 10px 0;
		.more {
			margin-top: -44px;
		}
	}
	@media screen and (max-width: 768px) {
		.more {
			margin-top: -34px;
		}
	}
	@media screen and (max-width: 420px) {
		.more {
			float: none;
			margin: 0 0 0;
		}
	}
}
.more {
	@include inline-block;
	font-family: $boldS;
	font-size: 16px;
	text-transform: uppercase;
	color: #87bc2c;
	position: relative;
	padding-right: 28px;
	&:after {
		content: '';
		width: 19px;
		height: 13px;
		background: url(../img/nextG.png) no-repeat center center;
		text-transform: uppercase;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		transition: 300ms;
	}
	&:hover:after {
		right: -5px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 14px;
		padding-right: 20px;
		&:after {
			width: 15px;
			height: 10px;
			background-size: contain;
			top: 45%;
		}
	}
}
.cat__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -32px;
	@media screen and (max-width: 1200px) {
		margin: 0 -10px;
	}
	@media screen and (max-width: 480px) {
		margin: 0;
	}
}
.cat__item {
	width: calc(33.33% - 30px);
	margin: 0 15px 30px;
	transition: 0ms;
	.cat__img {
		height: 220px;
		color: #333333;
		overflow: hidden;
		border-radius: 0 35px 0 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	.cat__title {
		display: flex;
		height: 90px;
		background-color: #e8e8e8;
		font-family: $boldS;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 10px;
		border-radius: 0 0 0 35px;
		transition: 300ms;
	}
	&:hover {
		color: #fff;
		.cat__title {
			background-color: #87bc2c;
		}
	}
	&.small {
		width: 260px;
		margin-left: 40px;
		margin-right: 40px;
	}
	@media screen and (max-width: 1024px) {
		width: calc(33.33% - 20px);
		margin: 0 10px 20px;
		.cat__img {
			height: 180px;
			border-radius: 0px 25px 0px 0px;
		}
		.cat__title {
			height: 80px;
			border-radius: 0 0 0 25px;
		}
		&.small {
			width: calc(33.33% - 20px);
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 768px) {
		.cat__img {
			height: 130px;
		}
		.title--txt {
			height: auto;
			min-height: 80px;
		}
	}
	@media screen and (max-width: 600px) {
		width: calc(50% - 20px);
		.cat__title {
			height: auto;
			min-height: 70px;
		}
		&.small {
			width: calc(50% - 20px);
		}
	}
	@media screen and (max-width: 480px) {
		width: 100%;
		margin: 0 0 20px;
		&:last-of-type {
			margin-bottom: 0!important;
		}
		&.small {
			width: 100%;
			margin: 0 0 20px;
		}
		.cat__img {
			height: 220px;
		}
		.cat__title {
			min-height: 60px;
		}
	}
	@media screen and (max-width: 340px) {
		.cat__img {
			height: 200px;
		}
	}
}
/* main catalog */

/* main partners */
.partners__slider {
	background-color: #f2f2f2;
	padding: 0 65px;
	.slick-track {
		display: flex;
		.slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active {
			border-right: 2px solid #c3c3c3;
		}
	}
	.slick-slide {
		display: flex;
		height: 90px;
		align-items: center;
		justify-content: center;
		padding: 5px;
		margin: 50px 5px;
		border-left: 2px solid #c3c3c3;
		overflow: hidden;
		outline: none;
		img {
			max-width: 100%;
			max-height: 100%;
			filter: grayscale(100%);
			object-fit: contain;
			object-position: center center;
			transition: 300ms;
		}
		&:hover img {
			opacity: 0.7!important;
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-track {
			.slick-active + .slick-active + .slick-active + .slick-active + .slick-active  {
				border-right: 2px solid #c3c3c3;
			}
		}
		.slick-slide {
			height: 70px;
			padding: 10px;
			margin: 30px 5px;
		}
	}
	@media screen and (max-width: 1200px) {
		padding: 0 30px;
		.slick-slide {
			border: none!important;
		}
	}
	@media screen and (max-width: 768px) {
		padding: 0 15px;
		.slick-slide {
			margin: 20px 0;
		}
	}
}
/* main partners */

/* main callback */
.callback {
	position: relative;
	color: #e6e6e6;
	h2 {
		font-size: 30px;
		margin-bottom: 20px;
	}
	&::before {
		content: '';
		background-color: rgba(0,0,0,0.5);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}
	&:after {
		content: '';
		background: linear-gradient(to top, rgba(135, 188, 44, 0.1), rgba(135, 188, 44, 0));
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
	}
	.container {
		padding-bottom: 400px;
	}
	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		position: absolute;
		z-index: -2;
		top: 0;
		left: 0;
	}
	h2 {
		color: #fff;
	}
	p a {
		color: #e6e6e6;
		font-family: $bold;
		pointer-events: none;
	}
	@media screen and (max-width: 1440px) {
		h2 {
			font-size: 26px;
		}
	}
	@media screen and (max-width: 1200px) {
		.container {
			padding-bottom: 75px;
		}
	}
	@media screen and (max-width: 1024px) {
		.container {
			padding-bottom: 50px;
		}
		h2 {
			font-size: 24px;
		}
		p a {
			pointer-events: auto;
		}
	}
	@media screen and (max-width: 768px) {
		.container {
			padding-bottom: 30px;
		}
		h2 {
			font-size: 22px;
			margin-bottom: 15px;
		}
		p a {
			display: table;
			margin-top: 2px;
		}
	}
	@media screen and (max-width: 600px) {
		h2 {
			font-size: 20px;
		}
	}
}
.callback__block {
	display: flex;
	position: relative;
	z-index: 1;
	.left {
		width: calc(100% - 555px);
		padding-right: 30px;
	}
	.right {
		width: 555px;
	}
	form {
		.g-recaptcha {
			@include inline-block;
		}
		.btn {
			float: right;
		}
	}
	@media screen and (max-width: 1024px) {
		.left {
			width: calc(100% - 475px);
		}
		.right {
			width: 475px;
		}
	}
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		.left {
			width: 100%;
		}
		.right {
			width: 100%;
		}
	}
	@media screen and (max-width: 460px) {
		form {
			.g-recaptcha {
				float: right;
				margin-bottom: 15px;
			}
		}
	}
	@media screen and (max-width: 340px) {
		form {
			.g-recaptcha {
				transform: scale(0.9);
				transform-origin: 100% 0;
				margin-bottom: 5px;
			}
		}
	}
}
/* main callback */

/* main news */
.news {
	position: relative;
	margin-top: -280px;
	&:after {
		content: '';
		background-color: #ffffff;
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		right: 50%;
		margin-right: -570px;
		border-radius: 0 140px 0 0;
		z-index: 0;
	}
	&:before {
		content: '';
		background-color: #f2f2f2;
		position: absolute;
		top: 280px;
		right: 0;
		left: 0;
		bottom: 0;
	}
	.container {
		position: relative;
		padding-left: 100px;
		padding-right: 100px;
		z-index: 1;
	}
	@media screen and (max-width: 1440px) {
		.container {
			padding-left: 75px;
			padding-right: 75px;
		}
		&:after {
			border-radius: 0 120px 0 0;
		}
	}
	@media screen and (max-width: 1200px) {
		margin: 0;
		.container {
			padding-left: 50px;
			padding-right: 50px;
		}
	}
	@media screen and (max-width: 1024px) {
		&:before, &:after {
			display: none;
		}
		.container {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
.news__block {
	display: flex;
	margin: 0 -15px;
	.news__item {
		display: flex;
		flex-direction: column;
		width: calc(50% - 30px);
		color: #464646;
		margin: 0 15px;
		transition: 0ms;
		.news__img {
			height: 225px;
			border-radius: 0 35px 0 0;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
		}
		.news__info {
			flex: 1 0 auto;
			background-color: #f2f2f2;
			border-radius: 0 0 0 35px;
			padding: 25px 30px 30px;
			position: relative;
			transition: 300ms;
			.date {
				display: flex;
				align-items: flex-end;
				width: 205px;
				height: 34px;
				background-color: #f2f2f2;
				color: #464646;
				border-radius: 0 17px 0 0;
				position: absolute;
				top: -34px;
				left: 0;
				padding: 5px 10px 0 30px;
				transition: 300ms;
			}
			h4 {
				font-family: $boldS;
				margin: 0 0 10px;
				transition: 300ms;
			}
			p {
				margin-bottom: 0;
				transition: 0ms;
			}
		}
		&:hover {
			color: #f3f8ea;
			.news__info {
				background-color: #87bc2c;
				transition: 300ms;
				.date {
					background-color: #87bc2c;
					color: #f3f8ea;
				}
				h4 {
					color: #fff;
					transition: 300ms;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.news__item {
			.news__img {
				border-radius: 0 25px 0 0;
			}
			.news__info {
				padding: 15px 20px 20px;
				border-radius: 0 0 0 25px;
				.date {
					width: 180px;
					height: 30px;
					padding-left: 20px;
					top: -30px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		margin: 0 -10px;
		.news__item {
			width: calc(50% - 20px);
			margin: 0 10px;
			.news__img {
				height: 175px;
			}
		}
	}
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
		margin: 0;
		.news__item {
			width: 100%;
			margin: 0 0 20px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
/* main news */

.btn--up {
	display: flex;
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 60px;
	right: 50px;
	font-family: 'fontAwesome';
	font-size: 24px;
	border-radius: 100%;
	background-color: #87bc2c;
	color: #fff;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	opacity: 0;
	pointer-events: none;
	z-index: 1;
	&:after {
		content: '\f077';
		margin-top: -5px;
		transition: 150ms;
	}
	&:focus {
		color: #fff;
	}
	&:hover {
		color: #fff;
		&:after {
			margin-top: -10px;
		}
	}
	&.active {
		opacity: 1;
		pointer-events: auto;
	}
	@media screen and (max-width: 1440px) {
		right: 25px;
		bottom: 50px;
	}
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

/* main content */