/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
  font-family: 'proxima_novablack';
  src: url("../fonts/proximanova-black-webfont.eot");
  src: url("../fonts/proximanova-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proximanova-black-webfont.woff2") format("woff2"), url("../fonts/proximanova-black-webfont.woff") format("woff"), url("../fonts/proximanova-black-webfont.ttf") format("truetype"), url("../fonts/proximanova-black-webfont.svg#proxima_novablack") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_novabold';
  src: url("../fonts/proximanova-bold-webfont.eot");
  src: url("../fonts/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proximanova-bold-webfont.woff2") format("woff2"), url("../fonts/proximanova-bold-webfont.woff") format("woff"), url("../fonts/proximanova-bold-webfont.ttf") format("truetype"), url("../fonts/proximanova-bold-webfont.svg#proxima_novabold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_novalight';
  src: url("../fonts/proximanova-light-webfont.eot");
  src: url("../fonts/proximanova-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proximanova-light-webfont.woff2") format("woff2"), url("../fonts/proximanova-light-webfont.woff") format("woff"), url("../fonts/proximanova-light-webfont.ttf") format("truetype"), url("../fonts/proximanova-light-webfont.svg#proxima_novalight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_novaregular';
  src: url("../fonts/proximanova-regular-webfont.eot");
  src: url("../fonts/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proximanova-regular-webfont.woff2") format("woff2"), url("../fonts/proximanova-regular-webfont.woff") format("woff"), url("../fonts/proximanova-regular-webfont.ttf") format("truetype"), url("../fonts/proximanova-regular-webfont.svg#proxima_novaregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_novasemibold';
  src: url("../fonts/proximanova-semibold-webfont.eot");
  src: url("../fonts/proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proximanova-semibold-webfont.woff2") format("woff2"), url("../fonts/proximanova-semibold-webfont.woff") format("woff"), url("../fonts/proximanova-semibold-webfont.ttf") format("truetype"), url("../fonts/proximanova-semibold-webfont.svg#proxima_novasemibold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_novaextrabold';
  src: url("../fonts/proximanova-extrabld-webfont.eot");
  src: url("../fonts/proximanova-extrabld-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proximanova-extrabld-webfont.woff2") format("woff2"), url("../fonts/proximanova-extrabld-webfont.woff") format("woff"), url("../fonts/proximanova-extrabld-webfont.ttf") format("truetype"), url("../fonts/proximanova-extrabld-webfont.svg#proxima_novaextrabold") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  box-sizing: border-box; }

*:after, *:before {
  display: block;
  line-height: 1; }

[data-src] {
  opacity: 0; }
  [data-src][src] {
    opacity: 1;
    transition-duration: 400ms;
    transition-delay: 0.2s; }

.preload {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none; }
  .preload.load {
    opacity: 0;
    transition: 500ms; }
  .preload.loadR {
    opacity: 1;
    transition: 500ms; }

@keyframes sk-double-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  position: relative;
  flex-direction: column;
  font-family: "proxima_novaregular";
  -webkit-text-size-adjust: 100%;
  color: #535353;
  margin: 0; }
  @media screen and (max-width: 1024px) {
    body {
      padding-top: 80px; } }
  @media screen and (max-width: 768px) {
    body {
      display: block; } }

h1 {
  font-family: "proxima_novaextrabold";
  font-weight: normal;
  font-size: 42px;
  text-transform: uppercase;
  color: #333333;
  line-height: 1;
  margin: 0 0 40px; }
  h1.h1 {
    font-family: "proxima_novaregular";
    font-size: 30px !important; }
  @media screen and (max-width: 1440px) {
    h1.h1 {
      font-size: 26px !important; } }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 32px;
      margin-bottom: 30px; }
      h1.h1 {
        font-size: 24px !important; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 28px;
      margin-bottom: 25px; }
      h1.h1 {
        font-size: 22px !important; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      margin-bottom: 15px; }
      h1.h1 {
        font-size: 20px !important; } }
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 22px; } }

h2 {
  font-family: "proxima_novaextrabold";
  font-weight: normal;
  font-size: 36px;
  color: #333333;
  line-height: 1;
  margin: 0 0 40px; }
  h2 a {
    color: #333333; }
    h2 a:hover {
      color: #87bc2c; }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 30px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 24px;
      margin-bottom: 25px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 20px; } }

h3 {
  font-family: "proxima_novabold";
  font-weight: normal;
  font-size: 24px;
  color: #333333; }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-family: "proxima_novabold";
  font-weight: normal;
  font-size: 20px;
  color: #333333; }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 18px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px; } }

h5 {
  font-family: "proxima_novabold";
  font-weight: normal;
  font-size: 18px;
  color: #333333; }
  @media screen and (max-width: 1024px) {
    h5 {
      font-size: 16px; } }

h6 {
  font-family: "proxima_novabold";
  font-weight: normal;
  font-size: 16px;
  color: #333333; }

p {
  margin: 0 0 15px; }

a {
  color: #535353;
  transition: 300ms;
  text-decoration: none; }
  a:focus {
    color: #535353;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #87bc2c; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.wrapper, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 16px; }
  .wrapper textarea,
  .wrapper input[type="text"],
  .wrapper input[type="email"],
  .wrapper input[type="search"],
  .wrapper input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    min-height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    font-family: "proxima_novaregular";
    font-size: 16px;
    padding: 15px 30px;
    margin-bottom: 15px;
    box-shadow: none;
    border: 1px solid #d2d1d2; }
    .wrapper textarea::placeholder,
    .wrapper input[type="text"]::placeholder,
    .wrapper input[type="email"]::placeholder,
    .wrapper input[type="search"]::placeholder,
    .wrapper input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #7c7b7b; }
  .wrapper .container, .page .container {
    width: 1140px; }
  .wrapper h2, .page h2 {
    text-transform: uppercase; }
  @media screen and (max-width: 1200px) {
    .wrapper .container, .page .container {
      width: 100%; }
    .wrapper textarea,
    .wrapper input[type="text"],
    .wrapper input[type="email"],
    .wrapper input[type="search"],
    .wrapper input[type="password"], .page textarea,
    .page input[type="text"],
    .page input[type="email"],
    .page input[type="search"],
    .page input[type="password"] {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (max-width: 1024px) {
    .wrapper, .page {
      font-size: 14px; }
      .wrapper textarea,
      .wrapper input[type="text"],
      .wrapper input[type="email"],
      .wrapper input[type="search"],
      .wrapper input[type="password"], .page textarea,
      .page input[type="text"],
      .page input[type="email"],
      .page input[type="search"],
      .page input[type="password"] {
        min-height: 45px;
        font-size: 14px;
        padding: 11px 20px; } }
  @media screen and (max-width: 768px) {
    .wrapper textarea,
    .wrapper input[type="text"],
    .wrapper input[type="email"],
    .wrapper input[type="search"],
    .wrapper input[type="password"], .page textarea,
    .page input[type="text"],
    .page input[type="email"],
    .page input[type="search"],
    .page input[type="password"] {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px;
      margin-bottom: 10px; } }

.wrapper .container {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media screen and (max-width: 1440px) {
    .wrapper .container {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media screen and (max-width: 1200px) {
    .wrapper .container {
      padding-top: 75px;
      padding-bottom: 75px; } }
  @media screen and (max-width: 1024px) {
    .wrapper .container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 768px) {
    .wrapper .container {
      padding-top: 30px;
      padding-bottom: 30px; } }

.container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%; } }

.close {
  width: 17px;
  height: 17px;
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #dddddd;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }
  @media screen and (max-width: 600px) {
    .close {
      top: 10px;
      right: 10px; } }

.btn {
  display: table;
  width: auto !important;
  height: 50px;
  background-color: #87bc2c !important;
  font-family: "proxima_novasemibold" !important;
  font-size: 16px;
  color: #fff !important;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  border: none;
  padding: 17px 40px 15px !important;
  line-height: 1;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: #87bc2c !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #333333 !important;
    color: #fff !important; }
  @media screen and (max-width: 1440px) {
    .btn {
      height: 45px;
      font-size: 14px;
      padding: 16px 35px 15px !important; } }
  @media screen and (max-width: 1024px) {
    .btn {
      height: 40px;
      padding: 14px 25px 13px !important; } }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    font-size: 20px;
    padding-left: 45px;
    margin: 25px 0; }
    .check--list li:before {
      content: '';
      width: 30px;
      height: 30px;
      background: url(../img/check.png) no-repeat center center;
      position: absolute;
      left: 0;
      top: 0px; }
  @media screen and (max-width: 1440px) {
    .check--list li {
      padding-left: 35px;
      font-size: 16px;
      margin: 20px 0; }
      .check--list li:before {
        width: 25px;
        height: 25px;
        background-size: contain; } }
  @media screen and (max-width: 1024px) {
    .check--list li {
      font-size: 14px;
      padding-left: 25px;
      margin: 15px 0; }
      .check--list li:before {
        width: 20px;
        height: 20px; } }

.circle--list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 20px 0; }
  .circle--list li {
    position: relative;
    padding-left: 16px;
    margin: 10px 0; }
    .circle--list li:after {
      content: '';
      width: 5px;
      height: 5px;
      background-color: #87bc2c;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 7px; }
  @media screen and (max-width: 1024px) {
    .circle--list li:after {
      top: 6px; } }

.col--location,
.col--phone,
.col--email {
  position: relative;
  padding-left: 22px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 16px;
    color: #87bc2c;
    position: absolute;
    top: 0px;
    left: 0; }
  @media screen and (max-width: 1024px) {
    .col--location,
    .col--phone,
    .col--email {
      padding-left: 20px; }
      .col--location:after,
      .col--phone:after,
      .col--email:after {
        font-size: 14px; } }

.col--location:after {
  content: '\f041'; }

.col--phone {
  pointer-events: none; }
  .col--phone:after {
    content: '\f095';
    top: 2px; }
  .col--phone a {
    display: table; }
  @media screen and (max-width: 1024px) {
    .col--phone a {
      pointer-events: auto; } }

.col--email:after {
  content: '\f0e0';
  font-size: 12px;
  top: 4px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

@media screen and (max-width: 1024px) {
  .col--email:after {
    font-size: 11px;
    top: 3px; } }

.green {
  color: #87bc2c; }

.title--txt {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  color: #87bc2c;
  margin-bottom: 10px; }
  @media screen and (max-width: 1024px) {
    .title--txt {
      margin-bottom: 5px; } }

/* header */
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 9;
  /* header logo */
  /* header logo */
  /* header up */
  /* header up */
  /* header nav */
  /* header nav */ }
  header .container {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  header > .container {
    height: 56px;
    align-items: center; }
  header .up {
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 16px; }
    header .up .container {
      height: 43px; }
    header .up .close {
      display: none; }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header a:hover, header a:focus {
    color: #fff; }
  header .logo {
    position: absolute;
    left: 90px;
    top: 11px; }
  header .choice--city {
    background: url(../img/arr-gr.svg) no-repeat center left;
    background-size: 18px;
    color: #fff;
    padding-left: 30px;
    margin-right: 90px; }
    header .choice--city:hover {
      color: #87bc2c; }
    header .choice--city a {
      color: #87bc2c;
      border-bottom: 1px dashed #87bc2c;
      position: relative; }
      header .choice--city a:after {
        content: '\f107';
        font-family: 'fontAwesome';
        font-size: 16px;
        color: #87bc2c;
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translateY(-50%); }
  header .phones {
    position: relative;
    padding-left: 22px;
    padding-right: 16px; }
    header .phones:after {
      content: '\f095';
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #87bc2c;
      position: absolute;
      left: 0;
      top: 2px; }
    header .phones a {
      pointer-events: none;
      color: #fff; }
    header .phones ul {
      display: none;
      background-color: #87bc2c;
      padding: 10px 15px;
      position: absolute;
      top: calc(100% + 11px);
      left: 50%;
      margin-left: -80px;
      z-index: 1; }
      header .phones ul li {
        margin: 2px 0;
        white-space: nowrap; }
  header .h--arr {
    pointer-events: auto !important;
    font-family: 'fontAwesome';
    font-size: 16px;
    color: #87bc2c;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-55%); }
    header .h--arr:after {
      content: '\f107'; }
    header .h--arr.open {
      transform: rotate(-180deg) translateY(50%); }
  header .nav > ul {
    display: flex; }
    header .nav > ul > li {
      position: relative;
      margin: 0 0 0 15px; }
      header .nav > ul > li > a {
        display: flex;
        align-items: center;
        height: 56px;
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        padding: 10px 0; }
        header .nav > ul > li > a:hover {
          color: #87bc2c; }
      header .nav > ul > li.active > a {
        color: #87bc2c; }
      header .nav > ul > li ul {
        display: none;
        background-color: #87bc2c;
        position: absolute;
        top: 100%;
        left: -28px;
        padding: 20px 30px 20px 10px;
        border-radius: 0px 0px 35px 0; }
        header .nav > ul > li ul li {
          display: block;
          white-space: nowrap;
          position: relative;
          margin: 0 18px; }
          header .nav > ul > li ul li:last-of-type:after {
            display: none; }
          header .nav > ul > li ul li a {
            color: #ffffff; }
            header .nav > ul > li ul li a:hover {
              text-decoration: underline; }
          header .nav > ul > li ul li.active a {
            text-decoration: underline; }
  @media screen and (max-width: 1440px) {
    header .logo {
      left: 50px; } }
  @media screen and (max-width: 1366px) {
    header .logo {
      left: 25px; }
    header .nav > ul > li ul {
      padding: 15px 25px 15px 15px;
      border-radius: 0 0 25px 0; }
      header .nav > ul > li ul li {
        margin: 0 15px; } }
  @media screen and (min-width: 1025px) {
    header.header {
      background-color: #ffffff;
      position: fixed;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
      header.header .up {
        color: #333333;
        position: relative; }
        header.header .up:before {
          content: '';
          background-color: #f2f2f2;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 50%;
          margin-left: -305px;
          border-radius: 0 0 0 45px;
          z-index: -1; }
      header.header .nav > ul > li > a {
        color: #333333; }
        header.header .nav > ul > li > a:hover {
          color: #87bc2c; }
      header.header .nav > ul > li.active > a {
        color: #87bc2c; }
      header.header .phones a {
        color: #333333; }
      header.header .phones ul li a {
        color: #fff; } }
  @media screen and (max-width: 1024px) {
    header {
      position: fixed;
      background-color: #87bc2c;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
      header .up {
        background-color: transparent; }
        header .up .container {
          height: 40px;
          justify-content: space-between; }
      header > .container {
        height: 40px; }
      header .choice--city a {
        color: #fff;
        border-bottom: 1px solid #fff; }
        header .choice--city a:after {
          color: #fff; }
      header .logo {
        width: 140px;
        top: 7px;
        left: 0;
        right: 0;
        margin: 0 auto; }
        header .logo img {
          width: 100%; }
      header .choice--city {
        margin: 0; }
      header .phones:after {
        color: #fff; }
      header .phones ul {
        z-index: 2;
        top: calc(100% + 5px);
        padding: 5px 10px;
        margin-left: -70px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3); }
      header .nav {
        display: none;
        max-height: calc(100% - 80px);
        background-color: #fff;
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        padding: 5px 15px;
        overflow: auto;
        border-top: 1px solid #87bc2c;
        border-bottom: 1px solid #87bc2c;
        z-index: 1; }
        header .nav > ul {
          display: block; }
          header .nav > ul > li {
            margin: 15px 0; }
            header .nav > ul > li > a {
              height: auto;
              font-size: 14px;
              color: #535353;
              padding: 0; }
            header .nav > ul > li ul {
              position: relative;
              top: 0;
              left: 0;
              padding: 5px 10px;
              border-radius: 0px;
              margin-top: 10px; }
              header .nav > ul > li ul li {
                display: table;
                white-space: inherit;
                margin: 5px 0; }
                header .nav > ul > li ul li:after {
                  display: none; }
      header .nav--btn {
        width: 35px;
        height: 30px;
        position: absolute;
        left: 12px;
        top: 35px;
        padding: 0;
        margin: 0;
        outline: none;
        float: none; }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #fff;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); } }
  @media screen and (max-width: 768px) {
    header {
      height: 80px; }
      header .up {
        display: none;
        background-color: rgba(135, 188, 44, 0.95);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        padding: 20px; }
        header .up .container {
          display: block;
          height: auto;
          padding: 0; }
        header .up .choice--city {
          display: table;
          padding: 0;
          background: none;
          border-bottom: 1px dashed #fff;
          margin: 0 auto 10px; }
        header .up .phones {
          display: table;
          margin: 0 auto; }
          header .up .phones a {
            pointer-events: auto; }
          header .up .phones .h--arr {
            display: none; }
          header .up .phones ul {
            display: block;
            background-color: transparent;
            position: relative;
            top: 0;
            left: 0;
            padding: 0;
            margin: 0;
            box-shadow: none; }
            header .up .phones ul li {
              margin: 2px 0 0; }
        header .up .close {
          display: block;
          top: 10px;
          right: 10px; }
      header > .container {
        height: auto; }
      header .nav--btn {
        top: 25px; }
      header .phones--btn {
        font-family: 'fontAwesome';
        font-size: 20px;
        position: fixed;
        top: 28px;
        right: 15px;
        color: #fff; }
        header .phones--btn:after {
          content: '\f095'; }
        header .phones--btn:hover, header .phones--btn:focus {
          color: #fff; } }
  @media screen and (max-width: 480px) {
    header .choice--city a {
      display: table;
      margin: 0 auto; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  margin-bottom: 0px !important; }
  .slider .slick-list {
    position: relative; }
    .slider .slick-list:before {
      content: '';
      width: 340px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1; }
    .slider .slick-list:after {
      content: '';
      width: 150px;
      height: 150px;
      background: url(../img/bg.png) no-repeat center center;
      position: absolute;
      top: 0;
      left: 340px; }
  .slider .slick-slide {
    height: 100vh;
    position: relative;
    outline: none; }
    .slider .slick-slide::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .slider .slick-slide:after {
      content: '';
      height: 220px;
      background: linear-gradient(to top, rgba(135, 188, 44, 0.3), rgba(135, 188, 44, 0));
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .slider .slick-slide .slider__block {
      width: 800px;
      position: absolute;
      left: 50%;
      top: 55%;
      font-size: 18px;
      color: #fff;
      margin-left: -225px;
      transform: translateY(-50%);
      z-index: 1; }
      .slider .slick-slide .slider__block .slider__title {
        display: block;
        font-family: "proxima_novaextrabold";
        font-size: 54px;
        text-transform: uppercase;
        margin-bottom: 25px; }
        .slider .slick-slide .slider__block .slider__title .light {
          display: block;
          font-family: "proxima_novalight"; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 25px; }
  .slider .slick-dots {
    display: flex;
    width: 340px;
    right: auto;
    justify-content: center;
    align-items: flex-end;
    top: 50%;
    bottom: auto;
    margin-top: -50px;
    line-height: 1; }
    .slider .slick-dots li {
      display: flex;
      width: auto;
      height: 58px;
      align-items: flex-end;
      margin: 0px;
      border-bottom: 2px solid #efefef;
      position: relative; }
      .slider .slick-dots li:after {
        content: '';
        height: 2px;
        background-color: #87bc2c;
        position: absolute;
        left: 0;
        bottom: -2px;
        transition: 300ms; }
      .slider .slick-dots li span {
        display: block;
        font-family: "proxima_novaextrabold";
        font-size: 24px;
        color: #c1c1c1;
        transition: 300ms;
        padding: 10px 0px 15px 15px; }
      .slider .slick-dots li.slick-active span {
        font-size: 50px;
        color: #333333;
        padding: 0 7px 8px 7px; }
      .slider .slick-dots li.slick-active:after {
        width: 100%; }
  .slider .slick-arrow {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border: 4px solid #f3f8e9;
    border-radius: 100%;
    z-index: 1;
    top: 50%;
    left: 140px;
    right: auto;
    transform: translate(0, 0);
    margin-top: -250px; }
    .slider .slick-arrow:before {
      display: none; }
    .slider .slick-arrow:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(../img/arr.png) no-repeat center center; }
    .slider .slick-arrow.slick-prev {
      margin-top: -175px; }
      .slider .slick-arrow.slick-prev:after {
        transform: rotate(-180deg); }
  @media screen and (max-width: 1440px) {
    .slider .slick-list:after {
      width: 125px;
      height: 125px;
      left: 275px;
      background-size: contain;
      background-position: top left; }
    .slider .slick-list:before {
      width: 275px; }
    .slider .slick-slide:after {
      height: 175px; }
    .slider .slick-slide .slider__block {
      font-size: 16px; }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 42px; }
    .slider .slick-arrow {
      width: 50px;
      height: 50px;
      left: 110px; }
      .slider .slick-arrow.slick-prev {
        margin-top: -135px; }
      .slider .slick-arrow.slick-next {
        margin-top: -200px; }
    .slider .slick-dots {
      width: 275px; }
      .slider .slick-dots li span {
        font-size: 20px; }
      .slider .slick-dots li.slick-active span {
        font-size: 40px; } }
  @media screen and (max-width: 1366px) {
    .slider .slick-list:after {
      width: 90px;
      height: 90px;
      left: 200px; }
    .slider .slick-list:before {
      width: 200px; }
    .slider .slick-arrow {
      width: 45px;
      height: 45px;
      left: 75px; }
      .slider .slick-arrow:after {
        background-size: 15px; }
      .slider .slick-arrow.slick-next {
        margin-top: -170px; }
      .slider .slick-arrow.slick-prev {
        margin-top: -115px; }
    .slider .slick-slide .slider__block {
      width: 600px; }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 38px;
        margin-bottom: 15px; }
    .slider .slick-dots {
      width: 200px;
      margin-top: -40px; }
      .slider .slick-dots li span {
        font-size: 18px;
        padding-bottom: 10px; }
      .slider .slick-dots li.slick-active span {
        font-size: 34px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px; } }
  @media screen and (max-width: 1024px) {
    .slider .slick-list:before, .slider .slick-list:after {
      display: none; }
    .slider .slick-slide {
      height: auto; }
      .slider .slick-slide:after {
        height: 130px; }
      .slider .slick-slide img {
        height: auto; }
      .slider .slick-slide .slider__block {
        width: auto;
        left: 60px;
        right: 60px;
        top: 50%;
        margin: 0; }
        .slider .slick-slide .slider__block .slider__title {
          font-size: 30px; }
    .slider .slick-dots {
      width: 100%;
      left: 0;
      right: 0;
      top: auto;
      margin: 0;
      bottom: 25px; }
      .slider .slick-dots li {
        height: 45px; }
        .slider .slick-dots li span {
          padding: 0 10px 10px; }
        .slider .slick-dots li.slick-active span {
          font-size: 28px;
          color: #87bc2c;
          padding-bottom: 8px; }
    .slider .slick-arrow {
      width: 35px;
      height: 35px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0; }
      .slider .slick-arrow.slick-prev {
        left: 10px;
        margin: 0; }
      .slider .slick-arrow.slick-next {
        left: auto;
        right: 10px;
        margin: 0; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide:after {
      height: 100px; }
    .slider .slick-slide .slider__block {
      font-size: 14px; }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 24px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 20px; }
    .slider .slick-arrow {
      width: 30px;
      height: 30px; } }
  @media screen and (max-width: 600px) {
    .slider .slick-slide .slider__block {
      left: 30px;
      right: 30px; }
    .slider .slick-arrow {
      display: none !important; } }
  @media screen and (max-width: 480px) {
    .slider .slick-slide {
      height: 300px; }
      .slider .slick-slide img {
        height: 100%; }
      .slider .slick-slide .slider__block {
        top: 40%;
        left: 20px;
        right: 20px; }
        .slider .slick-slide .slider__block .slider__title {
          font-size: 20px;
          margin-bottom: 10px; } }

.slider__list {
  display: flex;
  width: 520px;
  height: 475px;
  align-items: center;
  justify-content: center;
  background-color: #87bc2c;
  color: #fff;
  position: absolute;
  left: 0;
  top: 100vh;
  padding: 20px;
  margin-top: -385px;
  border-radius: 0px 0px 140px 0;
  z-index: 1; }
  .slider__list ul li {
    margin: 35px 0; }
  @media screen and (max-width: 1440px) {
    .slider__list {
      width: 360px;
      height: 320px;
      margin-top: -315px;
      border-radius: 0px 0px 120px 0; }
      .slider__list ul li {
        margin: 25px 0; } }
  @media screen and (max-width: 1366px) {
    .slider__list {
      width: 300px;
      height: 275px;
      border-radius: 0px 0px 100px 0;
      margin-top: -275px; }
      .slider__list ul li {
        margin: 20px 0; } }
  @media screen and (max-width: 1024px) {
    .slider__list {
      height: auto;
      width: 100%;
      position: relative;
      top: 0;
      margin: 0;
      border-radius: 0px 0px 50px 0; }
      .slider__list .check--list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
        .slider__list .check--list li {
          margin: 15px 15px; } }
  @media screen and (max-width: 768px) {
    .slider__list {
      border-radius: 0px 0px 35px 0; } }
  @media screen and (max-width: 480px) {
    .slider__list .check--list {
      width: 100%;
      flex-direction: column; }
      .slider__list .check--list li {
        margin: 10px 0; } }

.line1 {
  position: absolute;
  height: 100vh;
  width: 2px;
  background-color: #3c3d39;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -310px;
  z-index: 1; }
  @media screen and (max-width: 1024px) {
    .line1 {
      display: none; } }

.line2 {
  width: calc(100% - 340px);
  height: 2px;
  background-color: #3c3d39;
  position: absolute;
  right: 0;
  top: calc(100vh - 158px);
  z-index: 1; }
  @media screen and (max-width: 1440px) {
    .line2 {
      width: calc(100% - 275px);
      top: calc(100vh - 120px); } }
  @media screen and (max-width: 1366px) {
    .line2 {
      width: calc(100% - 200px);
      top: calc(100vh - 100px); } }
  @media screen and (max-width: 1024px) {
    .line2 {
      display: none; } }

.slider--plus {
  position: absolute;
  width: 24px;
  height: 24px;
  top: calc(100vh - 169px);
  z-index: 1;
  left: 50%;
  margin-left: -321px; }
  .slider--plus:before, .slider--plus:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #80a63f;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -1px; }
  .slider--plus:after {
    transform: rotate(-90deg); }
  @media screen and (max-width: 1440px) {
    .slider--plus {
      top: calc(100vh - 131px); } }
  @media screen and (max-width: 1366px) {
    .slider--plus {
      top: calc(100vh - 111 px); } }
  @media screen and (max-width: 1024px) {
    .slider--plus {
      display: none; } }

/* main slider */
/* main about */
.about {
  position: relative;
  overflow: hidden; }
  .about:before {
    content: '';
    width: 50%;
    background-color: #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1; }
  .about:after {
    content: '';
    width: 560px;
    height: 560px;
    background-color: #f2f2f2;
    border-radius: 100%;
    position: absolute;
    bottom: calc(100% - 155px);
    right: 50%;
    margin-right: -845px; }
  .about .container {
    background-color: #fff;
    border: 2px solid #f2f2f2;
    padding-bottom: 50px;
    padding-top: 175px; }
  .about h1 {
    font-size: 36px; }
  @media screen and (max-width: 1440px) {
    .about h1 {
      font-size: 32px; } }
  @media screen and (max-width: 1200px) {
    .about h1 {
      font-size: 28px; }
    .about .container {
      padding-top: 125px; } }
  @media screen and (max-width: 1024px) {
    .about:after {
      display: none; }
    .about h1 {
      font-size: 24px; }
    .about .container {
      border: none;
      padding-top: 75px;
      padding-bottom: 25px; } }
  @media screen and (max-width: 768px) {
    .about .container {
      padding-top: 50px;
      padding-bottom: 25px; } }
  @media screen and (max-width: 600px) {
    .about .container {
      padding-top: 30px; } }

.about__block {
  display: flex;
  align-items: flex-start; }
  .about__block .about__img {
    width: 690px;
    height: 690px;
    border-radius: 100%;
    padding: 80px;
    margin-left: -95px;
    margin-top: -75px;
    position: relative;
    z-index: 1; }
    .about__block .about__img:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #fff;
      z-index: -1;
      border-radius: 100%; }
    .about__block .about__img .img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 100%;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
    .about__block .about__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .about__block .right {
    width: calc(100% - 550px);
    margin-left: -20px;
    position: relative;
    z-index: 1; }
  .about__block .btn {
    margin-top: 35px; }
  @media screen and (max-width: 1200px) {
    .about__block .about__img {
      width: 450px;
      height: 450px;
      padding: 0;
      margin: 0; }
    .about__block .right {
      width: calc(100% - 450px);
      padding-left: 30px;
      margin: 0; } }
  @media screen and (max-width: 1024px) {
    .about__block .btn {
      margin-top: 25px; } }
  @media screen and (max-width: 768px) {
    .about__block .about__img {
      width: 350px;
      height: 350px; }
    .about__block .right {
      width: calc(100% - 350px); } }
  @media screen and (max-width: 600px) {
    .about__block {
      flex-wrap: wrap; }
      .about__block .about__img {
        margin: 0 auto;
        order: 2;
        margin-top: 25px; }
      .about__block .right {
        width: 100%;
        padding: 0;
        order: 1; } }
  @media screen and (max-width: 420px) {
    .about__block .about__img {
      width: 300px;
      height: 300px; } }
  @media screen and (max-width: 340px) {
    .about__block .about__img {
      width: 280px;
      height: 280px; } }

/* main about */
/* main catalog */
.cat .container {
  border: 2px solid #f2f2f2;
  border-top: none;
  border-bottom: none;
  padding-bottom: 100px; }

@media screen and (max-width: 1440px) {
  .cat .container {
    padding-bottom: 75px; } }

@media screen and (max-width: 1366px) {
  .cat .container {
    padding-bottom: 50px; } }

@media screen and (max-width: 1024px) {
  .cat .container {
    border: none;
    padding-bottom: 30px; } }

.cat__up {
  padding: 0px 100px 10px 100px; }
  .cat__up .more {
    float: right;
    margin-top: -69px; }
  @media screen and (max-width: 1200px) {
    .cat__up .more {
      margin-top: -54px; } }
  @media screen and (max-width: 1024px) {
    .cat__up {
      padding: 0px 0 10px 0; }
      .cat__up .more {
        margin-top: -44px; } }
  @media screen and (max-width: 768px) {
    .cat__up .more {
      margin-top: -34px; } }
  @media screen and (max-width: 420px) {
    .cat__up .more {
      float: none;
      margin: 0 0 0; } }

.more {
  display: inline-block;
  vertical-align: top;
  font-family: "proxima_novasemibold";
  font-size: 16px;
  text-transform: uppercase;
  color: #87bc2c;
  position: relative;
  padding-right: 28px; }
  .more:after {
    content: '';
    width: 19px;
    height: 13px;
    background: url(../img/nextG.png) no-repeat center center;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: 300ms; }
  .more:hover:after {
    right: -5px; }
  @media screen and (max-width: 1024px) {
    .more {
      font-size: 14px;
      padding-right: 20px; }
      .more:after {
        width: 15px;
        height: 10px;
        background-size: contain;
        top: 45%; } }

.cat__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -32px; }
  @media screen and (max-width: 1200px) {
    .cat__block {
      margin: 0 -10px; } }
  @media screen and (max-width: 480px) {
    .cat__block {
      margin: 0; } }

.cat__item {
  width: calc(33.33% - 30px);
  margin: 0 15px 30px;
  transition: 0ms; }
  .cat__item .cat__img {
    height: 220px;
    color: #333333;
    overflow: hidden;
    border-radius: 0 35px 0 0; }
    .cat__item .cat__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .cat__item .cat__title {
    display: flex;
    height: 90px;
    background-color: #e8e8e8;
    font-family: "proxima_novasemibold";
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border-radius: 0 0 0 35px;
    transition: 300ms; }
  .cat__item:hover {
    color: #fff; }
    .cat__item:hover .cat__title {
      background-color: #87bc2c; }
  .cat__item.small {
    width: 260px;
    margin-left: 40px;
    margin-right: 40px; }
  @media screen and (max-width: 1024px) {
    .cat__item {
      width: calc(33.33% - 20px);
      margin: 0 10px 20px; }
      .cat__item .cat__img {
        height: 180px;
        border-radius: 0px 25px 0px 0px; }
      .cat__item .cat__title {
        height: 80px;
        border-radius: 0 0 0 25px; }
      .cat__item.small {
        width: calc(33.33% - 20px);
        margin-left: 10px;
        margin-right: 10px; } }
  @media screen and (max-width: 768px) {
    .cat__item .cat__img {
      height: 130px; }
    .cat__item .title--txt {
      height: auto;
      min-height: 80px; } }
  @media screen and (max-width: 600px) {
    .cat__item {
      width: calc(50% - 20px); }
      .cat__item .cat__title {
        height: auto;
        min-height: 70px; }
      .cat__item.small {
        width: calc(50% - 20px); } }
  @media screen and (max-width: 480px) {
    .cat__item {
      width: 100%;
      margin: 0 0 20px; }
      .cat__item:last-of-type {
        margin-bottom: 0 !important; }
      .cat__item.small {
        width: 100%;
        margin: 0 0 20px; }
      .cat__item .cat__img {
        height: 220px; }
      .cat__item .cat__title {
        min-height: 60px; } }
  @media screen and (max-width: 340px) {
    .cat__item .cat__img {
      height: 200px; } }

/* main catalog */
/* main partners */
.partners__slider {
  background-color: #f2f2f2;
  padding: 0 65px; }
  .partners__slider .slick-track {
    display: flex; }
    .partners__slider .slick-track .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active {
      border-right: 2px solid #c3c3c3; }
  .partners__slider .slick-slide {
    display: flex;
    height: 90px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 50px 5px;
    border-left: 2px solid #c3c3c3;
    overflow: hidden;
    outline: none; }
    .partners__slider .slick-slide img {
      max-width: 100%;
      max-height: 100%;
      filter: grayscale(100%);
      object-fit: contain;
      object-position: center center;
      transition: 300ms; }
    .partners__slider .slick-slide:hover img {
      opacity: 0.7 !important; }
  @media screen and (max-width: 1440px) {
    .partners__slider .slick-track .slick-active + .slick-active + .slick-active + .slick-active + .slick-active {
      border-right: 2px solid #c3c3c3; }
    .partners__slider .slick-slide {
      height: 70px;
      padding: 10px;
      margin: 30px 5px; } }
  @media screen and (max-width: 1200px) {
    .partners__slider {
      padding: 0 30px; }
      .partners__slider .slick-slide {
        border: none !important; } }
  @media screen and (max-width: 768px) {
    .partners__slider {
      padding: 0 15px; }
      .partners__slider .slick-slide {
        margin: 20px 0; } }

/* main partners */
/* main callback */
.callback {
  position: relative;
  color: #e6e6e6; }
  .callback h2 {
    font-size: 30px;
    margin-bottom: 20px; }
  .callback::before {
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
  .callback:after {
    content: '';
    background: linear-gradient(to top, rgba(135, 188, 44, 0.1), rgba(135, 188, 44, 0));
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; }
  .callback .container {
    padding-bottom: 400px; }
  .callback > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0; }
  .callback h2 {
    color: #fff; }
  .callback p a {
    color: #e6e6e6;
    font-family: "proxima_novabold";
    pointer-events: none; }
  @media screen and (max-width: 1440px) {
    .callback h2 {
      font-size: 26px; } }
  @media screen and (max-width: 1200px) {
    .callback .container {
      padding-bottom: 75px; } }
  @media screen and (max-width: 1024px) {
    .callback .container {
      padding-bottom: 50px; }
    .callback h2 {
      font-size: 24px; }
    .callback p a {
      pointer-events: auto; } }
  @media screen and (max-width: 768px) {
    .callback .container {
      padding-bottom: 30px; }
    .callback h2 {
      font-size: 22px;
      margin-bottom: 15px; }
    .callback p a {
      display: table;
      margin-top: 2px; } }
  @media screen and (max-width: 600px) {
    .callback h2 {
      font-size: 20px; } }

.callback__block {
  display: flex;
  position: relative;
  z-index: 1; }
  .callback__block .left {
    width: calc(100% - 555px);
    padding-right: 30px; }
  .callback__block .right {
    width: 555px; }
  .callback__block form .g-recaptcha {
    display: inline-block;
    vertical-align: top; }
  .callback__block form .btn {
    float: right; }
  @media screen and (max-width: 1024px) {
    .callback__block .left {
      width: calc(100% - 475px); }
    .callback__block .right {
      width: 475px; } }
  @media screen and (max-width: 768px) {
    .callback__block {
      flex-wrap: wrap; }
      .callback__block .left {
        width: 100%; }
      .callback__block .right {
        width: 100%; } }
  @media screen and (max-width: 460px) {
    .callback__block form .g-recaptcha {
      float: right;
      margin-bottom: 15px; } }
  @media screen and (max-width: 340px) {
    .callback__block form .g-recaptcha {
      transform: scale(0.9);
      transform-origin: 100% 0;
      margin-bottom: 5px; } }

/* main callback */
/* main news */
.news {
  position: relative;
  margin-top: -280px; }
  .news:after {
    content: '';
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 50%;
    margin-right: -570px;
    border-radius: 0 140px 0 0;
    z-index: 0; }
  .news:before {
    content: '';
    background-color: #f2f2f2;
    position: absolute;
    top: 280px;
    right: 0;
    left: 0;
    bottom: 0; }
  .news .container {
    position: relative;
    padding-left: 100px;
    padding-right: 100px;
    z-index: 1; }
  @media screen and (max-width: 1440px) {
    .news .container {
      padding-left: 75px;
      padding-right: 75px; }
    .news:after {
      border-radius: 0 120px 0 0; } }
  @media screen and (max-width: 1200px) {
    .news {
      margin: 0; }
      .news .container {
        padding-left: 50px;
        padding-right: 50px; } }
  @media screen and (max-width: 1024px) {
    .news:before, .news:after {
      display: none; }
    .news .container {
      padding-left: 15px;
      padding-right: 15px; } }

.news__block {
  display: flex;
  margin: 0 -15px; }
  .news__block .news__item {
    display: flex;
    flex-direction: column;
    width: calc(50% - 30px);
    color: #464646;
    margin: 0 15px;
    transition: 0ms; }
    .news__block .news__item .news__img {
      height: 225px;
      border-radius: 0 35px 0 0;
      overflow: hidden; }
      .news__block .news__item .news__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .news__block .news__item .news__info {
      flex: 1 0 auto;
      background-color: #f2f2f2;
      border-radius: 0 0 0 35px;
      padding: 25px 30px 30px;
      position: relative;
      transition: 300ms; }
      .news__block .news__item .news__info .date {
        display: flex;
        align-items: flex-end;
        width: 205px;
        height: 34px;
        background-color: #f2f2f2;
        color: #464646;
        border-radius: 0 17px 0 0;
        position: absolute;
        top: -34px;
        left: 0;
        padding: 5px 10px 0 30px;
        transition: 300ms; }
      .news__block .news__item .news__info h4 {
        font-family: "proxima_novasemibold";
        margin: 0 0 10px;
        transition: 300ms; }
      .news__block .news__item .news__info p {
        margin-bottom: 0;
        transition: 0ms; }
    .news__block .news__item:hover {
      color: #f3f8ea; }
      .news__block .news__item:hover .news__info {
        background-color: #87bc2c;
        transition: 300ms; }
        .news__block .news__item:hover .news__info .date {
          background-color: #87bc2c;
          color: #f3f8ea; }
        .news__block .news__item:hover .news__info h4 {
          color: #fff;
          transition: 300ms; }
  @media screen and (max-width: 1024px) {
    .news__block .news__item .news__img {
      border-radius: 0 25px 0 0; }
    .news__block .news__item .news__info {
      padding: 15px 20px 20px;
      border-radius: 0 0 0 25px; }
      .news__block .news__item .news__info .date {
        width: 180px;
        height: 30px;
        padding-left: 20px;
        top: -30px; } }
  @media screen and (max-width: 768px) {
    .news__block {
      margin: 0 -10px; }
      .news__block .news__item {
        width: calc(50% - 20px);
        margin: 0 10px; }
        .news__block .news__item .news__img {
          height: 175px; } }
  @media screen and (max-width: 600px) {
    .news__block {
      flex-wrap: wrap;
      margin: 0; }
      .news__block .news__item {
        width: 100%;
        margin: 0 0 20px; }
        .news__block .news__item:last-of-type {
          margin-bottom: 0; } }

/* main news */
.btn--up {
  display: flex;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 60px;
  right: 50px;
  font-family: 'fontAwesome';
  font-size: 24px;
  border-radius: 100%;
  background-color: #87bc2c;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  opacity: 0;
  pointer-events: none;
  z-index: 1; }
  .btn--up:after {
    content: '\f077';
    margin-top: -5px;
    transition: 150ms; }
  .btn--up:focus {
    color: #fff; }
  .btn--up:hover {
    color: #fff; }
    .btn--up:hover:after {
      margin-top: -10px; }
  .btn--up.active {
    opacity: 1;
    pointer-events: auto; }
  @media screen and (max-width: 1440px) {
    .btn--up {
      right: 25px;
      bottom: 50px; } }
  @media screen and (max-width: 1024px) {
    .btn--up {
      display: none; } }

/* main content */
/* page content */
.page {
  padding-top: 100px;
  padding-bottom: 50px; }
  .page .news1 .date, .page .news .date {
    color: #87bc2c; }
  .page .page_nav .active {
    color: #87bc2c; }
  .page #ask-form {
    max-width: 350px; }
  .page .write {
    color: #87bc2c;
    text-decoration: none; }
    .page .write:hover {
      text-decoration: underline; }
  .page .back_link {
    margin: 25px 0; }
  .page p a {
    color: #87bc2c;
    text-decoration: underline; }
    .page p a:hover {
      text-decoration: none; }
  .page h1 {
    font-size: 36px; }
  .page .h2 a {
    font-size: 16px;
    color: #87bc2c; }
  .page.no-padding {
    overflow: hidden;
    padding-bottom: 0; }
  .page .small--photos a {
    display: block;
    height: 180px;
    overflow: hidden; }
    .page .small--photos a img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      object-position: center top; }
  @media screen and (max-width: 1440px) {
    .page h1 {
      font-size: 32px; } }
  @media screen and (max-width: 1200px) {
    .page h1 {
      font-size: 28px; }
    .page p iframe {
      max-width: 100% !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; } }
  @media screen and (max-width: 1024px) {
    .page {
      padding-top: 0;
      padding-bottom: 30px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; }
      .page h1 {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .page h1 {
      font-size: 22px; }
    .page .small--photos a {
      height: 140px; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img, .page h2 img, .page h3 img, .page h4 img, .page h5 img, .page h6 img, .page p a img, .page strong img {
      max-width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page #page-text p a img {
      width: auto !important;
      max-width: 100% !important; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 14px;
  padding: 35px 0px 45px; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #87bc2c;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        text-decoration: none;
        color: #666666; }
        .breadcrumbs ul li a:hover {
          color: #87bc2c; }
      .breadcrumbs ul li:after {
        content: '';
        width: 12px;
        height: 2px;
        background-color: #8c8c8c;
        margin: 8px 7px 0px 7px;
        color: #282727;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  @media screen and (max-width: 1440px) {
    .breadcrumbs {
      font-size: 14px;
      padding: 25px 0 35px; }
      .breadcrumbs ul li:after {
        margin-top: 8px; } }
  @media screen and (max-width: 1024px) {
    .breadcrumbs {
      padding: 10px 0 20px;
      line-height: 1; }
      .breadcrumbs ul li:after {
        width: 8px;
        margin: 6px 5px 0; } }

/* breadcrumbs */
/* page about */
.page .about:after {
  display: none; }

.page .about .container {
  border: none;
  padding-top: 0px; }

.page .about .slider__list {
  height: 310px;
  width: 100%;
  position: relative;
  top: 0;
  margin: -220px 0 0 215px;
  border-radius: 0px 0px 0px 180px;
  padding-left: 335px;
  margin-bottom: 60px;
  z-index: 0; }
  .page .about .slider__list:after {
    content: '';
    width: 100vw;
    background-color: #87bc2c;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0; }
  .page .about .slider__list .check--list {
    width: 100%; }
    .page .about .slider__list .check--list li {
      margin: 45px 0; }
      .page .about .slider__list .check--list li:before {
        top: -3px; }
      .page .about .slider__list .check--list li br {
        display: none; }

.page .about__block {
  align-items: flex-start;
  position: relative; }
  .page .about__block .about__img {
    margin-top: -70px;
    margin-left: -120px;
    z-index: 1; }
    .page .about__block .about__img:before {
      display: none; }
  .page .about__block:before {
    content: '';
    position: absolute;
    left: -140px;
    top: -80px;
    width: 690px;
    height: 690px;
    border-radius: 100%;
    background-color: #fff;
    z-index: -1; }

.page h1 {
  position: relative;
  z-index: 1; }

.page .about__down {
  padding: 0 45px; }

@media screen and (max-width: 1440px) {
  .page .about .slider__list {
    height: 250px;
    padding-left: 240px; }
    .page .about .slider__list .check--list li {
      margin: 35px 0; }
  .page .about__block:before {
    width: 600px;
    height: 600px;
    left: -130px;
    top: -60px; }
  .page .about__block .about__img {
    width: 600px;
    height: 600px;
    padding: 60px;
    margin-top: -50px; } }

@media screen and (max-width: 1200px) {
  .page .about .slider__list {
    height: auto;
    padding: 15px 50px;
    margin: 25px 0 0 0;
    border-radius: 0 0 0 75px; }
    .page .about .slider__list:after {
      display: none; }
    .page .about .slider__list .check--list li {
      margin: 25px 0; }
  .page .about__block {
    align-items: center; }
    .page .about__block:before {
      display: none; }
    .page .about__block .about__img {
      width: 400px;
      height: 400px;
      padding: 0;
      margin: 15px 0; }
    .page .about__block .right {
      width: calc(100% - 400px); } }

@media screen and (max-width: 1024px) {
  .page .about__block .about__img {
    width: 300px;
    height: 300px; }
  .page .about__block .right {
    width: calc(100% - 300px); }
  .page .about .slider__list {
    padding: 25px 20px;
    border-radius: 0 0 0 50px; }
    .page .about .slider__list .check--list li {
      margin: 10px 15px; }
  .page .about__down {
    padding: 0; }
    .page .about__down h6 {
      margin: 30px 0; } }

@media screen and (max-width: 768px) {
  .page .about .slider__list {
    padding: 25px 15px;
    border-radius: 0 0 0 35px; }
  .page .about__down h6 {
    margin-bottom: 20px; } }

@media screen and (max-width: 600px) {
  .page .about__block .right {
    width: 100%;
    margin-bottom: 15px; }
  .page .about__block .about__img {
    margin: 0 auto; }
  .page .about .slider__list {
    padding: 20px 20px; }
    .page .about .slider__list .check--list {
      display: block; }
      .page .about .slider__list .check--list li {
        margin: 15px 0; } }

@media screen and (max-width: 420px) {
  .page .about__block .about__img {
    width: 290px;
    height: 290px; } }

.about__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 15px;
  border-top: 2px solid #f2f2f2; }
  .about__btns a {
    display: flex;
    width: 360px;
    height: 97px;
    background-color: #f2f2f2;
    align-items: center;
    justify-content: center;
    font-family: "proxima_novasemibold";
    font-size: 20px;
    color: #333333;
    border-radius: 0 35px 0 35px;
    margin: 0 80px; }
    .about__btns a span {
      display: block;
      padding-right: 50px;
      position: relative; }
      .about__btns a span:after {
        content: '';
        width: 19px;
        height: 13px;
        background: url(../img/nextG.png) no-repeat center center;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: 300ms; }
    .about__btns a:hover {
      background-color: #87bc2c;
      color: #fff; }
      .about__btns a:hover span:after {
        background: url(../img/nextW.png) no-repeat center center; }
  @media screen and (max-width: 1200px) {
    .about__btns {
      padding: 50px 15px; } }
  @media screen and (max-width: 1024px) {
    .about__btns {
      padding: 30px 15px; }
      .about__btns a {
        width: 300px;
        height: 80px;
        font-size: 18px;
        margin: 0 15px; }
        .about__btns a span {
          padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .about__btns a {
      width: 250px;
      height: 70px;
      font-size: 16px;
      border-radius: 0 25px 0 25px; }
      .about__btns a span {
        padding-right: 25px; }
        .about__btns a span:after {
          width: 16px;
          height: 10px;
          background-size: contain; } }
  @media screen and (max-width: 600px) {
    .about__btns {
      flex-wrap: wrap;
      padding: 20px 15px 0; }
      .about__btns a {
        height: 60px;
        margin: 10px auto; } }

/* page about */
/* page catalog */
.line--right {
  max-width: 1140px;
  border-right: 2px solid #f2f2f2;
  padding-bottom: 50px;
  margin: 0 auto; }
  .line--right .container {
    padding-left: 0;
    padding-right: 0; }
  @media screen and (max-width: 1200px) {
    .line--right {
      border: none; }
      .line--right .container {
        padding-right: 15px;
        padding-left: 15px; } }
  @media screen and (max-width: 1024px) {
    .line--right {
      padding-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    .line--right {
      padding-bottom: 15px; } }

.page .cat__block {
  margin: 0 -15px; }

@media screen and (max-width: 1024px) {
  .page .cat__block {
    margin: 0 -10px; } }

@media screen and (max-width: 480px) {
  .page .cat__block {
    margin: 0; } }

.item--title h1.h1 {
  font-size: 24px !important; }

@media screen and (max-width: 1024px) {
  .item--title h1.h1 {
    font-size: 22px !important; } }

@media screen and (max-width: 768px) {
  .item--title h1.h1 {
    font-size: 20px !important; } }

.cat__txt {
  border-top: 2px solid #f6f6f6;
  margin-bottom: -50px;
  position: relative; }
  .cat__txt:before {
    content: '';
    width: 50%;
    background-color: #f2f2f2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1; }
  .cat__txt .container {
    background-color: #fff;
    padding: 65px 95px;
    border-right: 2px solid #f6f6f6; }
  @media screen and (max-width: 1200px) {
    .cat__txt .container {
      padding: 50px  70px; } }
  @media screen and (max-width: 1024px) {
    .cat__txt {
      margin-bottom: -30px; }
      .cat__txt .container {
        padding: 30px  50px; } }
  @media screen and (max-width: 768px) {
    .cat__txt .container {
      padding: 20px 15px; } }
  @media screen and (max-width: 480px) {
    .cat__txt {
      margin-top: 15px; } }

.items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .items .items__col {
    display: flex;
    background-color: #e8e8e8;
    flex-direction: column;
    width: calc(25% - 30px);
    font-family: "proxima_novasemibold";
    color: #333333;
    margin: 0 15px 30px;
    border-radius: 0 35px 0 35px;
    overflow: hidden; }
    .items .items__col .items__img {
      height: 215px; }
      .items .items__col .items__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .items .items__col .items__title {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 90px;
      padding: 15px 10px; }
    .items .items__col:hover {
      background-color: #87bc2c;
      color: #fff; }
  @media screen and (min-width: 1440px) {
    .items .items__col:nth-last-of-type(-n+4) {
      margin-bottom: 10px; } }
  @media screen and (max-width: 1024px) {
    .items {
      margin: 0 -10px; }
      .items .items__col {
        width: calc(25% - 20px);
        margin: 0 10px 20px; }
        .items .items__col .items__img {
          height: 175px; }
        .items .items__col .items__title {
          min-height: 80px; } }
  @media screen and (max-width: 768px) {
    .items .items__col {
      width: calc(50% - 20px);
      border-radius: 0 25px 0 25px; }
      .items .items__col .items__img {
        height: 220px; } }
  @media screen and (max-width: 480px) {
    .items {
      margin: 0; }
      .items .items__col {
        width: 100%;
        margin: 0 0 20px; }
        .items .items__col .items__title {
          min-height: 70px; } }
  @media screen and (max-width: 340px) {
    .items .items__col .items__img {
      height: 200px; } }

.items__txt {
  background-color: #f2f2f2;
  padding: 70px 0 120px;
  margin: 0 0 -50px; }
  @media screen and (max-width: 1440px) {
    .items__txt {
      padding: 50px 0 80px; } }
  @media screen and (max-width: 1200px) {
    .items__txt {
      padding: 50px 0 50px; } }
  @media screen and (max-width: 1024px) {
    .items__txt {
      padding: 30px 0;
      margin-bottom: -30px; } }
  @media screen and (max-width: 768px) {
    .items__txt {
      padding: 25px 0;
      margin-top: 30px; } }

.item {
  display: flex;
  justify-content: flex-end;
  min-height: 710px;
  position: relative;
  margin-bottom: -50px; }
  .item .left {
    display: flex;
    background-color: #f2f2f2;
    flex-direction: column;
    width: calc(50vw - 80px);
    overflow: hidden;
    border-radius: 0px 105px 0px 0px;
    align-items: flex-end;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(50% + 80px);
    padding: 30px 30px 90px 0; }
  .item .right {
    width: 50%;
    padding-right: 70px; }
  .item ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .item .item__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -35px; }
    .item .item__list li {
      width: 50%;
      padding-right: 15px;
      margin-bottom: 25px; }
      .item .item__list li img {
        display: block;
        height: 64px;
        max-width: 100%;
        object-fit: contain;
        object-position: center left;
        margin-bottom: 15px; }
      .item .item__list li p {
        margin-bottom: 5px; }
      .item .item__list li strong {
        font-family: "proxima_novabold"; }
  .item .txt {
    min-height: 520px;
    padding-bottom: 20px; }
  .item .item__down {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    padding: 50px 20px 50px 0;
    margin-top: 20px; }
    .item .item__down:before {
      content: '';
      width: 60vw;
      height: 2px;
      background: #f2f2f2;
      position: absolute;
      top: 0;
      left: -80px; }
    .item .item__down p {
      margin-bottom: 5px; }
    .item .item__down .item__price .price {
      font-family: "proxima_novaextrabold";
      font-size: 24px;
      color: #333333; }
  .item .guarantee {
    width: 100%;
    padding-left: 70px;
    position: relative;
    margin-bottom: 20px; }
    .item .guarantee img {
      width: 45px;
      position: absolute;
      left: 0;
      top: 0; }
    .item .guarantee h5 {
      text-transform: uppercase;
      margin: 0 0 5px; }
  @media screen and (max-width: 1024px) {
    .item {
      min-height: 0px;
      flex-wrap: wrap;
      padding: 0 15px;
      margin-bottom: -30px; }
      .item .left {
        width: 100%;
        position: relative;
        right: 0;
        border-radius: 0 75px 0 0;
        padding-bottom: 20px; }
      .item .right {
        width: 100%;
        padding: 0;
        margin-top: 20px; }
      .item .txt {
        min-height: 0px;
        padding-bottom: 0; }
      .item .item__list {
        margin-right: 0; }
      .item .item__down {
        padding: 20px 0 40px;
        border-top: 2px solid #f2f2f2; }
        .item .item__down:before {
          display: none; }
      .item .guarantee {
        padding-left: 50px; }
        .item .guarantee img {
          width: 40px; } }
  @media screen and (max-width: 768px) {
    .item .left {
      padding: 20px 20px 20px 0;
      border-radius: 0 50px 0 0; } }
  @media screen and (max-width: 480px) {
    .item .left {
      border-radius: 0 35px 0 0; } }

.tg {
  font-weight: 900; }

.item__for {
  width: 100%; }
  .item__for .slick-slide {
    height: 490px;
    outline: none;
    padding: 0; }
    .item__for .slick-slide img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center left; }
  @media screen and (max-width: 1024px) {
    .item__for .slick-slide {
      height: 350px; } }
  @media screen and (max-width: 768px) {
    .item__for .slick-slide {
      height: 300px; } }
  @media screen and (max-width: 600px) {
    .item__for .slick-slide {
      height: 250px; } }
  @media screen and (max-width: 480px) {
    .item__for .slick-slide {
      height: 200px; } }

.item__nav {
  width: 460px;
  padding-right: 25px;
  margin-top: 20px; }
  .item__nav .slick-slide {
    height: 135px;
    margin: 0 7.5px;
    outline: none;
    position: relative;
    cursor: pointer; }
    .item__nav .slick-slide:after {
      content: '';
      height: 6px;
      background: #87bc2c;
      position: absolute;
      left: 0;
      right: 100%;
      bottom: 0;
      transition: 300ms; }
    .item__nav .slick-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .item__nav .slick-slide.slick-current:after {
      right: 0; }
  .item__nav .slick-arrow {
    width: 18px;
    height: 16px;
    transition: 300ms; }
    .item__nav .slick-arrow:before {
      display: none; }
    .item__nav .slick-arrow:after {
      content: '';
      background: url(../img/arr.png) no-repeat center center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .item__nav .slick-arrow.slick-prev {
      display: none !important; }
    .item__nav .slick-arrow.slick-next {
      right: 0; }
    .item__nav .slick-arrow:hover {
      opacity: 0.7; }
  @media screen and (max-width: 600px) {
    .item__nav {
      width: 375px; }
      .item__nav .slick-slide {
        height: 100px; }
        .item__nav .slick-slide:after {
          height: 4px; } }
  @media screen and (max-width: 480px) {
    .item__nav {
      width: 100%;
      padding: 0 20px 0 10px; } }
  @media screen and (max-width: 380px) {
    .item__nav .slick-slide {
      height: 80px; } }

.pagination {
  margin: 30px 0 65px; }
  .pagination ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .pagination ul li a {
      display: flex;
      height: 100%;
      width: 50px;
      justify-content: flex-end;
      align-items: flex-end;
      font-family: "proxima_novaextrabold";
      font-size: 18px;
      color: #c1c1c1;
      line-height: 1;
      border-bottom: 2px solid #efefef;
      padding: 0 0 14px 0; }
      .pagination ul li a:hover {
        color: #333;
        border-bottom: 2px solid #87bc2c; }
    .pagination ul li.active a {
      width: auto;
      font-size: 30px;
      color: #333333;
      color: #333;
      border-bottom: 2px solid #87bc2c;
      padding: 0 0 11px 0; }
  @media screen and (max-width: 1440px) {
    .pagination {
      margin: 0px 0 50px; } }
  @media screen and (max-width: 1024px) {
    .pagination {
      margin-top: 10px;
      margin-bottom: 35px; }
      .pagination ul li a {
        width: 35px;
        font-size: 16px;
        padding-bottom: 10px; }
      .pagination ul li.active a {
        font-size: 24px;
        padding-bottom: 8px; } }
  @media screen and (max-width: 768px) {
    .pagination {
      margin-bottom: 15px; } }

/* page catalog */
/* page gallery */
.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .gallery .gallery__item {
    width: calc(33.33% - 30px);
    background-color: #f2f2f2;
    font-family: "proxima_novasemibold";
    font-size: 16px;
    text-align: center;
    color: #333333;
    overflow: hidden;
    margin: 0 15px 30px;
    border-radius: 0 40px 0 40px; }
    .gallery .gallery__item .gallery__img {
      height: 250px; }
      .gallery .gallery__item .gallery__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .gallery .gallery__item .gallery__title {
      display: flex;
      min-height: 70px;
      align-items: center;
      justify-content: center;
      padding: 10px; }
    .gallery .gallery__item:hover {
      background-color: #87bc2c;
      color: #fff; }
  @media screen and (max-width: 1024px) {
    .gallery {
      margin: 0 -10px; }
      .gallery .gallery__item {
        width: calc(33.33% - 20px);
        font-size: 14px;
        margin: 0 10px 20px;
        border-radius: 0 35px 0 35px; }
        .gallery .gallery__item .gallery__img {
          height: 200px; }
        .gallery .gallery__item .gallery__title {
          min-height: 60px; } }
  @media screen and (max-width: 768px) {
    .gallery .gallery__item {
      border-radius: 0 25px 0 25px; }
      .gallery .gallery__item .gallery__img {
        height: 160px; } }
  @media screen and (max-width: 600px) {
    .gallery .gallery__item {
      width: calc(50% - 20px); } }
  @media screen and (max-width: 420px) {
    .gallery {
      margin: 0; }
      .gallery .gallery__item {
        width: 100%;
        margin: 0 0 20px; }
        .gallery .gallery__item .gallery__img {
          height: 220px; } }
  @media screen and (max-width: 340px) {
    .gallery .gallery__item .gallery__img {
      height: 200px; } }

/* page gallery */
/* page news */
.news--item {
  display: flex;
  min-height: 215px;
  background-color: #f2f2f2;
  color: #464646;
  overflow: hidden;
  border-radius: 0px 35px 0 35px;
  margin-bottom: 30px; }
  .news--item:last-of-type {
    margin-bottom: 45px; }
  .news--item .news--item__img {
    width: 360px; }
    .news--item .news--item__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .news--item .news--item__block {
    width: calc(100% - 360px);
    padding: 35px 30px 20px 30px; }
    .news--item .news--item__block .date {
      display: block;
      color: #6b6b6b;
      margin-bottom: 26px;
      transition: 300ms; }
    .news--item .news--item__block h4 {
      font-family: "proxima_novasemibold";
      margin: 0 0 15px;
      transition: 300ms; }
    .news--item .news--item__block p {
      margin-bottom: 0; }
  .news--item:hover {
    background-color: #87bc2c;
    color: #f3f8ea; }
    .news--item:hover .news--item__block .date {
      color: #f3f8ea; }
    .news--item:hover .news--item__block h4 {
      color: #fff; }
  @media screen and (max-width: 1024px) {
    .news--item {
      margin-bottom: 20px; }
      .news--item:last-of-type {
        margin-bottom: 30px; }
      .news--item .news--item__img {
        width: 300px; }
      .news--item .news--item__block {
        width: calc(100% - 300px);
        padding: 30px 20px 20px 20px; }
        .news--item .news--item__block .date {
          margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .news--item {
      border-radius: 0 25px 0 25px; }
      .news--item .news--item__img {
        width: 250px; }
      .news--item .news--item__block {
        width: calc(100% - 250px); } }
  @media screen and (max-width: 480px) {
    .news--item {
      flex-wrap: wrap; }
      .news--item .news--item__img {
        width: 100%;
        height: 200px; }
      .news--item .news--item__block {
        width: 100%;
        padding: 20px; }
        .news--item .news--item__block .date {
          margin-bottom: 10px; } }
  @media screen and (max-width: 380px) {
    .news--item .news--item__img {
      height: 175px; } }

/* page news */
/* page contacts */
.map {
  display: none;
  height: 400px;
  position: relative; }
  .map:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
    pointer-events: none; }
  .map > ymaps, .map .ymaps-2-1-74-map {
    height: 100% !important; }
  @media screen and (max-width: 1440px) {
    .map {
      height: 350px; } }
  @media screen and (max-width: 1024px) {
    .map {
      height: 300px; } }
  @media screen and (max-width: 768px) {
    .map {
      height: 250px; } }
  @media screen and (max-width: 600px) {
    .map {
      height: 220px; } }

.cts--tab {
  display: flex;
  width: 1140px;
  justify-content: space-between;
  margin: 0 auto 75px; }
  .cts--tab .cts--tab__item {
    width: calc(50% - 15px);
    background-color: #f2f2f2;
    padding: 30px 50px 10px 75px;
    border-radius: 0 0 0 35px;
    position: relative;
    cursor: pointer;
    transition: 300ms; }
    .cts--tab .cts--tab__item .cts__title {
      display: flex;
      width: 210px;
      height: 62px;
      font-family: "proxima_novabold";
      font-size: 20px;
      text-transform: uppercase;
      color: #333333;
      text-transform: uppercase;
      background-color: #f2f2f2;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 0 17px 0 0;
      padding: 10px 10px 0 10px;
      position: absolute;
      left: 0;
      top: -62px;
      transition: 300ms; }
    .cts--tab .cts--tab__item .col--location:after,
    .cts--tab .cts--tab__item .col--phone:after,
    .cts--tab .cts--tab__item .col--email:after {
      transition: 300ms; }
    .cts--tab .cts--tab__item:hover, .cts--tab .cts--tab__item.active {
      background-color: #87bc2c;
      color: #fff; }
      .cts--tab .cts--tab__item:hover a, .cts--tab .cts--tab__item.active a {
        color: #fff; }
      .cts--tab .cts--tab__item:hover .cts__title, .cts--tab .cts--tab__item.active .cts__title {
        background-color: #87bc2c;
        color: #fff; }
      .cts--tab .cts--tab__item:hover .col--location:after,
      .cts--tab .cts--tab__item:hover .col--phone:after,
      .cts--tab .cts--tab__item:hover .col--email:after, .cts--tab .cts--tab__item.active .col--location:after,
      .cts--tab .cts--tab__item.active .col--phone:after,
      .cts--tab .cts--tab__item.active .col--email:after {
        color: #c3de96; }
  .cts--tab ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .cts--tab ul li {
      margin: 0 0 27px; }
  @media screen and (max-width: 1200px) {
    .cts--tab {
      width: 100%;
      padding: 0 15px; } }
  @media screen and (max-width: 1024px) {
    .cts--tab {
      margin-bottom: 50px; }
      .cts--tab .cts--tab__item {
        padding: 20px 30px 20px 50px; }
        .cts--tab .cts--tab__item .cts__title {
          height: 55px;
          width: 180px;
          font-size: 18px;
          top: -55px; }
      .cts--tab ul li {
        margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    .cts--tab {
      margin-bottom: 30px; }
      .cts--tab .cts--tab__item {
        width: calc(50% - 10px);
        padding: 20px 20px 20px 30px; }
        .cts--tab .cts--tab__item .cts__title {
          width: 150px;
          height: 50px;
          font-size: 16px;
          top: -50px; }
      .cts--tab ul li {
        margin-bottom: 15px; } }
  @media screen and (max-width: 600px) {
    .cts--tab .cts--tab__item {
      padding: 15px 15px 10px 15px;
      border-radius: 0 0 0 25px; }
      .cts--tab .cts--tab__item .cts__title {
        height: 45px;
        width: 120px;
        font-size: 14px;
        top: -45px; } }
  @media screen and (max-width: 480px) {
    .cts--tab {
      flex-wrap: wrap;
      width: auto;
      margin: 0; }
      .cts--tab .cts--tab__item {
        width: calc(100% + 30px);
        background-color: #fff;
        border-radius: 0;
        margin: 0 -15px; }
        .cts--tab .cts--tab__item .cts__title {
          width: auto;
          height: auto;
          background-color: transparent;
          justify-content: flex-start;
          position: relative;
          text-align: left;
          padding: 0;
          top: 0;
          margin-bottom: 10px;
          border-radius: 0; }
        .cts--tab .cts--tab__item:hover .cts__title {
          background-color: transparent; } }

.cts--form {
  background-color: #f2f2f2;
  padding: 65px 0 120px;
  margin-bottom: -50px; }
  .cts--form h2 {
    font-size: 30px;
    margin-bottom: 15px; }
  .cts--form p {
    margin-bottom: 25px; }
  .cts--form .cts--form__inp {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7.5px; }
    .cts--form .cts--form__inp input {
      width: calc(33.33% - 15px);
      margin: 0 7.5px 15px; }
    .cts--form .cts--form__inp.down {
      justify-content: space-between;
      align-items: flex-start; }
      .cts--form .cts--form__inp.down textarea {
        height: 77px;
        width: calc(100% - 335px);
        margin: 0 7.5px 15px; }
      .cts--form .cts--form__inp.down .g-recaptcha {
        margin: 0 7.5px 15px; }
  .cts--form .btn {
    margin: 0 auto; }
  @media screen and (max-width: 1440px) {
    .cts--form {
      padding: 50px 0 80px; }
      .cts--form h2 {
        font-size: 26px; } }
  @media screen and (max-width: 1200px) {
    .cts--form h2 {
      font-size: 24px; } }
  @media screen and (max-width: 1024px) {
    .cts--form {
      padding: 35px 0 50px;
      margin-bottom: -30px; }
      .cts--form h2 {
        font-size: 22px; } }
  @media screen and (max-width: 768px) {
    .cts--form {
      padding: 30px 0 30px; }
      .cts--form .cts--form__inp input {
        margin-bottom: 10px; }
      .cts--form .cts--form__inp.down textarea {
        margin-bottom: 10px; } }
  @media screen and (max-width: 600px) {
    .cts--form .cts--form__inp.down textarea {
      width: 100%; }
    .cts--form .btn {
      float: right;
      margin-top: -92px; } }
  @media screen and (max-width: 460px) {
    .cts--form {
      padding-bottom: 160px; }
      .cts--form .cts--form__inp input {
        width: calc(50% - 15px); }
        .cts--form .cts--form__inp input[type="email"] {
          width: calc(100% - 15px); }
      .cts--form .cts--form__inp.down {
        display: block;
        margin: 0; }
        .cts--form .cts--form__inp.down textarea {
          margin: 0 0 10px; }
        .cts--form .cts--form__inp.down .g-recaptcha {
          float: right;
          margin: 0 0 15px 0; }
      .cts--form .btn {
        margin: 0; } }
  @media screen and (max-width: 340px) {
    .cts--form .cts--form__inp {
      display: block;
      margin: 0; }
      .cts--form .cts--form__inp input {
        width: 100%;
        margin: 0 0 10px; }
        .cts--form .cts--form__inp input[type="email"] {
          width: 100%;
          margin: 0 0 10px; }
      .cts--form .cts--form__inp.down .g-recaptcha {
        transform: scale(0.9);
        transform-origin: 100% 0;
        margin-bottom: 5px; } }

/* page contacts */
/* modal */
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding: 50px 20px;
  overflow: auto; }
  .modal .modal__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal__block {
    width: 480px;
    background-color: #fff;
    position: absolute;
    padding: 20px;
    left: 0;
    right: 0;
    top: 50%;
    margin: 25px auto;
    transform: translateY(-50%); }
    .modal .modal__block h4 {
      font-family: "proxima_novasemibold";
      font-size: 20px;
      font-weight: normal;
      text-align: center;
      padding: 0 20px;
      margin: 0; }
    .modal .modal__block h5 {
      font-family: "proxima_novasemibold";
      text-align: center;
      margin: 15px 0; }
    .modal .modal__block h2 {
      font-family: "proxima_novaextrabold";
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 30px; }
  .modal form {
    display: block;
    padding-bottom: 130px;
    margin-top: 15px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 50px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      font-family: "proxima_novaregular";
      font-size: 16px;
      padding: 15px 30px;
      margin-bottom: 15px;
      box-shadow: none;
      border: 1px solid #d2d1d2; }
      .modal form textarea::placeholder,
      .modal form input[type="text"]::placeholder,
      .modal form input[type="email"]::placeholder,
      .modal form input[type="search"]::placeholder,
      .modal form input[type="password"]::placeholder {
        color: #7c7b7b; }
    .modal form textarea {
      height: 130px; }
    .modal form .g-recaptcha {
      float: right; }
    .modal form .btn {
      float: right;
      margin: 15px 0 0 0; }
  .modal .modal__choice {
    display: flex;
    justify-content: space-between; }
    .modal .modal__choice ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .modal .modal__choice ul li a {
        display: table;
        font-size: 16px;
        color: #333333;
        padding: 8px 20px; }
        .modal .modal__choice ul li a:hover {
          background-color: #87bc2c;
          color: #fff; }
      .modal .modal__choice ul li.active a {
        background-color: #87bc2c;
        color: #fff;
        font-family: "proxima_novasemibold"; }
  .modal#modal--choice .modal__block {
    width: 755px;
    padding: 50px; }
  @media screen and (max-width: 1200px) {
    .modal .modal__block h4 {
      font-size: 18px; }
    .modal .modal__block h2 {
      font-size: 26px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 50px;
      font-size: 16px;
      padding: 14px 15px; } }
  @media screen and (max-width: 1024px) {
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px;
      margin-bottom: 15px; }
    .modal form .btn {
      width: 150px !important; }
    .modal .modal__block h2 {
      font-size: 24px;
      margin-bottom: 20px; }
    .modal .modal__choice ul li a {
      font-size: 14px;
      padding: 5px 15px; } }
  @media screen and (max-width: 768px) {
    .modal .modal__block h4 {
      font-size: 16px; }
    .modal .modal__block h2 {
      font-size: 20px;
      margin-bottom: 15px; }
    .modal#modal--choice .modal__block {
      width: auto;
      left: 15px;
      right: 15px;
      padding: 30px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      margin-bottom: 10px; }
    .modal form textarea {
      height: 100px; } }
  @media screen and (max-width: 600px) {
    .modal .modal__choice {
      flex-wrap: wrap; }
      .modal .modal__choice ul {
        width: 50%;
        margin-bottom: 15px; }
        .modal .modal__choice ul:nth-last-of-type(-n+2) {
          margin-bottom: 0; }
    .modal .modal__block h2 {
      font-size: 18px; } }
  @media screen and (max-width: 500px) {
    .modal .modal__block {
      width: auto;
      left: 10px;
      right: 10px; }
    .modal .modal__choice ul li a {
      font-size: 13px;
      padding: 4px 10px; } }
  @media screen and (max-width: 380px) {
    .modal form {
      padding-bottom: 120px; }
      .modal form .g-recaptcha {
        transform: scale(0.85);
        transform-origin: 100% 0%; }
      .modal form .btn {
        margin-top: 0; } }

.call--btn {
  position: fixed;
  bottom: 150px;
  right: 40px;
  display: table;
  background-color: #87bc2c;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 23px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  outline: none !important;
  z-index: 1; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 35px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #87bc2c;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s;
    border: 1px solid #87bc2c; }
  @media screen and (max-width: 1440px) {
    .call--btn {
      margin-right: 0;
      right: 35px;
      bottom: 75px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 15px;
      right: 15px;
      bottom: 80px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      bottom: 50px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* modal */
/* page content */
/* footer */
footer {
  background-color: #333333;
  font-family: "proxima_novalight";
  font-size: 16px;
  color: #cbcbcb; }
  footer a {
    color: #cbcbcb; }
    footer a:focus {
      color: #cbcbcb; }
    footer a:hover {
      color: #87bc2c; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    footer ul li {
      margin: 15px 0; }
      footer ul li.active a {
        color: #87bc2c; }
  footer .circle--list li:after {
    top: 7px; }
  footer h4 {
    color: #ffffff;
    text-transform: uppercase;
    margin: 0 0 25px; }
  footer p {
    margin-bottom: 20px; }
  footer .up {
    border-bottom: 2px solid #5c5c5c;
    margin-bottom: 70px; }
  footer .container {
    display: flex;
    justify-content: space-between; }
  footer .footer__nav {
    display: flex;
    width: 100%;
    height: 95px;
    justify-content: space-between;
    align-items: center; }
    footer .footer__nav li a {
      color: #ffffff;
      text-transform: uppercase; }
      footer .footer__nav li a:focus {
        color: #fff; }
      footer .footer__nav li a:hover {
        color: #87bc2c; }
    footer .footer__nav li.active a {
      color: #87bc2c; }
  footer .footer__item {
    padding-right: 15px; }
    footer .footer__item.f--cts {
      padding-right: 0; }
      footer .footer__item.f--cts ul li {
        margin-bottom: 20px; }
  footer .down {
    background-color: #292929;
    font-size: 14px;
    color: #a1a1a1;
    margin-top: 50px; }
    footer .down .container {
      height: 60px;
      align-items: center; }
    footer .down p {
      margin: 0; }
    footer .down a {
      color: #a1a1a1; }
      footer .down a:hover {
        color: #a1a1a1; }
    footer .down .artmedia {
      display: flex;
      align-items: center; }
  @media screen and (max-width: 1440px) {
    footer .footer__nav {
      height: 80px;
      font-size: 14px; }
    footer .up {
      margin-bottom: 50px; }
    footer .down {
      margin-top: 40px; } }
  @media screen and (max-width: 1200px) {
    footer .footer__nav {
      height: 70px; }
    footer .up {
      margin-bottom: 30px; }
    footer h4 {
      margin-bottom: 20px; }
    footer .down {
      margin-top: 30px; } }
  @media screen and (max-width: 1024px) {
    footer {
      font-size: 14px; }
      footer > .container {
        flex-wrap: wrap; }
      footer .footer__item {
        width: 50%;
        margin-bottom: 20px; }
        footer .footer__item.f--cts {
          width: 100%;
          margin-bottom: 0; }
        footer .footer__item ul {
          margin: 10px 0; }
      footer h4 {
        margin-bottom: 15px; }
      footer .circle--list li:after {
        top: 6px; } }
  @media screen and (max-width: 768px) {
    footer {
      padding-top: 30px; }
      footer .footer__nav {
        display: none; }
      footer .up {
        border: none;
        margin: 0; }
      footer .down {
        margin-top: 10px; } }
  @media screen and (max-width: 600px) {
    footer .down {
      text-align: center; }
      footer .down .container {
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 15px; }
      footer .down .artmedia {
        margin-top: 5px; } }
  @media screen and (max-width: 480px) {
    footer .footer__item.f--nav {
      display: none; } }

/* footer */
